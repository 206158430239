/*
 * Localize Links 2.0 - in this version, all data- attributes are put on the anchor tag itself. If there is HTML in the anchor tag, keep it and append the text as need
 */
var loclink = {
	init: function ($container) {
		var localeDataCounter = 0;
		var localeDataCheck = setInterval(function() {
			if (typeof localeData === 'object') {
				clearInterval(localeDataCheck);
				localeDataCheck = false;
				if (typeof localeData.status == 'string') {
					var localeCode = localeData.status.toLowerCase();
					//limit to $container until footer is redesigned
					$container.find('.loc-link.hidden').each(function () {
						var $link = $(this),
							useCountryCode = $link.data('use-country');
							useEU = $link.data('use-iseu');

							if (useCountryCode) localeCode = localeData.country.toLowerCase();
							if (useEU & localeData.isEU === 'true') localeCode = 'eu';
						loclink.update($link, localeCode);
					});
				}
			}
			// stop checking for vertical column alignment after 5 seconds
			if (localeDataCounter < 100) {
				localeDataCounter++;
			} else {
				if (localeDataCheck != false) {
					clearInterval(localeDataCheck);
					localeDataCheck = false;
				}
			}
		},50);
	},
	update: function ($link,localeCode) {
		if (loclink.excludeLocale($link,localeCode)) {
			$link.remove();
		} else {
			var url = loclink.getValue($link,localeCode,'Url'), // U is uppercase because jQuery camelCase data- attribute names
				img = loclink.getValue($link,localeCode,'Img'),
			    text = loclink.getValue($link,localeCode,'Text'); // T is uppercase because jQuery camelCase data- attribute names

			if ($link.hasClass('loc-text')) {
				if (!text) {
					$link.remove();
				} else {
					$link.html(text).removeClass('hidden');
				}
			}
			else {
				// update localized link
				if (!url) {
					$link.remove();
				} else {
					$link.prop('href',url).html(text).removeClass('hidden');
				}
				if (img){
					$link.css('background-image', 'url("' + img + '")').removeClass('hidden');
				}
			}
		}
	},
	getValue: function ($link,localeCode,attr) {
		var attrVal;
		if (typeof $link.data(localeCode + attr) != 'undefined' && $link.data(localeCode + attr) != '') {
			attrVal = $link.data(localeCode + attr);
		} else if (typeof $link.data(attr.toLowerCase()) != 'undefined' && $link.data(attr.toLowerCase()) != '') {
			attrVal = $link.data(attr.toLowerCase());
		}
		return attrVal;
	},
	excludeLocale: function ($link,localeCode) {
		// if a country is set to exclude, clear all vars to not display link in nav
		if (typeof $link.data('exclude') != 'undefined' && $link.data('exclude') != '') {
			var excludeCountry = $link.data('exclude').split(',');
			for (var i = 0; i < excludeCountry.length; i++) {
				if (excludeCountry[i] == localeCode) {
					// only return true if country is in list
					return true;
				}
			}
		}
		return false;
	}
};

/*
 * Locale Content - is a simple way to turn on / off blocks of content in a page based on a user's locale
 */
var localeContent = {
	init: function ($localeContent,countryCode) {
		$localeContent.each(function () {
			var $content = $(this),
			    dataAttr = $content.data(),
			    key = countryCode.toLowerCase() + 'Visible',
			    isVisible;

			// check to see if country visible is set as a data- attribute, if not default to data-visible
			if (typeof dataAttr[key] === 'undefined') {
				// check to see if content has 'data-is-eu', if so utilize localeData to set isVisible otherwise look for individual country data attribute
				if ($content.data('is-eu')) {
					isVisible = localeData.isEU == 'true';
				} else {
					isVisible = dataAttr['visible'];
				}
			} else {
				isVisible = dataAttr[key];
			}

			// update display for
			localeContent.display($content,isVisible);
		});
	},
	display: function ($content, isVisible) {
		if (isVisible === true) {
			$content.removeClass('hidden').find('input, select').not('.force-disabled').prop('disabled', false);
		} else {
			$content.addClass('hidden').find('input, select').prop('disabled', true);
		}
	}
};

/*
 * Locale Date Format - changes the order of month and day to day and month for countries outside of NAM
 */
var localeDateFormat = {
	init: function (countryCode, $birthmonth, $birthday, $dob) {
		if (countryCode === 'US' || countryCode === 'CA' || countryCode === 'MX') {
			localeDateFormat.updateOrder(true, $birthmonth, $birthday, $dob);
		} else {
			localeDateFormat.updateOrder(false, $birthmonth, $birthday, $dob);
		}
	},
	updateOrder: function (isMonthFirst, $birthmonth, $birthday, $dob) {
		var dobFormat = $dob.data('format');

		if (isMonthFirst === true && dobFormat !== 'monthddyyyy' ) {
			//$birthmonth.insertBefore($birthday);
			var $targetBirthmonth = $(this);
			var $parent = $(this).parent();
			$birthday.each(function () {
				var $targetBirthday = $(this);
				if ($targetBirthday.parent().is($parent)) {
					$targetBirthmonth.insertBefore($targetBirthday);
				}
			});
			$dob.data('format','monthddyyyy');
		}

		if (isMonthFirst === false && dobFormat !== 'ddmonthyyyy') {
			//$birthday.insertBefore($birthmonth);
			$birthday.each(function () {
				var $targetBirthday = $(this);
				var $parent = $(this).parent();
				$birthmonth.each(function () {
					var $targetBirthmonth = $(this);
					if ($targetBirthmonth.parent().is($parent)) {
						$targetBirthday.insertBefore($targetBirthmonth);
					}
				});
			});
			$dob.data('format','ddmonthyyyy');
		}
	}
};

/*
 * Locale Notice - display a modal notice to the user when current page is not intended for current URL. Alternative urls are provided to user.
 */
var localeNotice = {

	init: function ($container) {
		var checkingLocale = setInterval(function () {
			//make sure loc has been defined, before running locale check
			if (typeof localeData != 'undefined') {
				clearInterval(checkingLocale);
				var countryCode = localeData.country.toLowerCase(),
				    isIntl = localeNotice.isIntl(countryCode);
				//if the check comes back as false, trigger the locale notice modal window
				if (localeNotice.check($container, countryCode, isIntl) == false) {
					localeNotice.display($container, countryCode, isIntl);
				}
			}
		}, 50);
	},

	isIntl: function (countryCode) {
		// test to find if user's country is considered to be intl
		switch(countryCode) {
			case 'us':
			case 'ca':
			case 'mx':
			case 'pr':
			case 'bm':
			case 'uk':
			case 'gb':
			case 'au':
			case 'nz':
			case 'hk':
			case 'sg':
			case 'vn':
			case 'bn':
			case 'my':
			case 'id':
			case 'cn':
			case 'jp':
			case 'kr':
			case 'tw':
				return false;
			default:
				return true;
		}
	},

	check: function ($container, countryCode, isIntl) {
		//check if localeContainer is on page
		if ($container.length > 0) {
			// check if user's country is considered to be intl
			if (isIntl) {
				// check if path matches url provided in $container for intl users
				if ($container.data('intl') != undefined && window.location.pathname == $container.data('intl')) {
					$container.attr('data-loc','intl').trigger('localeNotice.true');
					return true;
				} else {
					$container.attr('data-loc','intl').trigger('localeNotice.false');
					return false;
				}
			} else {
				// check if path matches url provided in $container for user's current country
				if ($container.data(countryCode) != undefined && $container.data(countryCode) == window.location.pathname) {
					$container.attr('data-loc', countryCode).trigger('localeNotice.true');
					return true;
				} else {
					$container.attr('data-loc', countryCode).trigger('localeNotice.false');
					return false;
				}
			}
		} else {
			return;
		}
	},

	display: function ($container, countryCode, isIntl) {
		$.magnificPopup.open({
			items: {
				src: $container.html()
			},
			type: 'inline',
			removalDelay: 300,
			modal: true,
			mainClass: 'border-box-sizing local-alert',
			overflowY: 'scroll',
			callbacks: {
				elementParse: function(item) {
					var $source = $(item.src),
					    localeURL = ((isIntl === true) ? $container.data('intl') : $container.data(countryCode));

					//if a locale url is not available for user's locale, hide the green button
					if (typeof localeURL == 'undefined') {
						$source.find('.title').text($container.data('altTitle'));
						$source.find('.message').text($container.data('altMessage'));
						$source.find('.cta-btn').parent('div').addClass('hidden');
						$source.find('.link-btn').text($container.data('altLinkText')).attr('href', $container.data('altLinkUrl'));
					} else {
						$source.find('.title').text($container.data('title'));
						$source.find('.message').text($container.data('message'));
						$source.find('.cta-btn').text($container.data('ctaText')).attr('href', localeURL);
						$source.find('.link-btn').text($container.data('linkText')).attr('href', $container.data('linkUrl'));
					}

					//pass the update lightbox markup to the plugin
					item.src = $source;
				}
			}
		});
	}
};
