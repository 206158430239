
var addUrl = {
	/**
	 * element - get the url and then make the call to update the link
	 * @param  {jQuery Object} $element - element on the page that is being tested
	 */
	element: function ($element) {
		var url = addUrl.getUrl($element);
		addUrl.toHref($element, url);
	},

	/**
	 * urlTest - check the $element class names to find the correct url to use. Note, this is dependent on the global url vars defined in book-www-urls.js.
	 * @param  {jQuery Object} $element - element on the page that is being tested
	 * @return {string}                 - return the url to be populated
	 */
	getUrl: function ($element) {
		var url;
		if ($element.hasClass('addwww')) {
			url = $wwwURL;
			// pageProtocol is defined in 
			if (pageProtocol === 'https:') {
				url = $swwwURL;
			}
		} else if ($element.hasClass('addswww')) {
			url = $swwwURL;
		} else if ($element.hasClass('addbook')) {
			url = $bookURL;
		}
		return url;
	},

	/**
	 * toHref - update href
	 * @param  {jQuery Object} $element - element on the page that is being tested
	 * @param  {string} url             - book or www url for current environment
	 */
	toHref: function ($element, url) {
		// only apply update to <a>
		if ($element.is('a')) {
			var path = $element.attr('href');
			if (path.substring(0, 1) === '/') {
				$element.attr('href', url + path).addClass('url-added');
			}
		}
	}
};

(function ($) {
	$(document).ready(function () {
		// find elements on the page that need the url added
		var addUrlElements = $(document).find('.addwww, .addbook, .addswww');
		if (addUrlElements.length > 0) {
			addUrlElements.each(function () {
				addUrl.element($(this));
			});
		}
		// find elements on the page after P13 runs that need the url added
		$(document).on('p13nAfterDelivery', function (e, data) {
			addUrlP13Elements = $(document).find('a.addwww, a.addbook, a.addswww').not('.url-added');
			if (addUrlP13Elements.length > 0) {
				addUrlP13Elements.each(function () {
					addUrl.element($(this));
				});
			}
		});
		// find elements in lightboxes
		$(document).on('mfpAjaxContentAdded', function (e) {
			addUrlLightboxElements = $.magnificPopup.instance.content.find('a.addwww, a.addbook, a.addswww').not('.url-added');
			if (addUrlLightboxElements.length > 0) {
				addUrlLightboxElements.each(function () {
					addUrl.element($(this));
				});
			}
		});
	});
})(jQuery);