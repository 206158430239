/*
 * Saved Cruise Count - used to display the saved cruise count in the header of the web site.
 */
var savedCruiseCount = {
	/**
	 * $badge - var - jQuery Object for the saved cruise count element in the header 
	 */
	$badge: jQuery('.saved-cruise-count'),

	/**
	 * [display] - inserts the count to the $badge in the header. Will also turn on the $badge and change the saved cruise icon to filled heart if needed.
	 * @param  {number} count - number returned in the saved cruises cookie or ajax call
	 */
	display: function (count) {
		savedCruiseCount.$badge.text(count);
		$svgHeartIcon = savedCruiseCount.$badge.parent().find('.svg-icon');
		$svgHeartIcon.find('use').attr('xlink:href','#icon-heart');
		savedCruiseCount.$badge.show();
	},

	/** 
	 * unload - removes the count from the $badge in the header. Will also turn off the $badge and change the saved cruise icon to outlined heart if needed.
	 */
	unload: function () {
		savedCruiseCount.$badge.text('');
		$svgHeartIcon = savedCruiseCount.$badge.parent().find('.svg-icon');
		$svgHeartIcon.find('use').attr('xlink:href','#icon-heart-outline');
		savedCruiseCount.$badge.hide();
	},

	/**
	 * [load] - ajax call to fetch the saved cruise count. If request is successful, the count will be displayed and saved in a cookie
	 */
	load: function () {
		jQuery.ajax({
			url: $swwwURL + '/cruise-with-me/savedCruisesCount.do',
			cache: false,
			contentType: 'application/json',
			dataType: 'json',
			xhrFields: {
				withCredentials: true
			}
		})
		.done(function(results) {
			if (results.status == "success") {
				savedCruiseCount.display(results.data);
				savedCruiseCount.save(results.data);
			}
		});
	},

	/**
	 * [save] - save the count object in a cookie. Cookie expiration has two options:
	 *          1. will expire at the end of the current session if the user does not have "remember me" enabled
	 *          2. will expire in one year, the same time as the "remember me" cookie will
	 * @param  {number} count - number returned in the ajax call
	 */
	save: function (count) {
		//remove cookie if one exist before saving new cookie.
		if (docCookies.hasItem('savedCruises') != null) {
			docCookies.removeItem('savedCruises', '/', '.princess.com')
		}
		var obj = '{"count":' + count + '}',
			expires = 0;
		
		docCookies.setItem('savedCruises', obj, expires, '/', '.princess.com', true);
	},

	/**
	 * [update] - updates the count in the header and saved cruises cookie
	 * @param  {number} count - number provided by functionality when adding or removing a saved cruise from FNC and GVP
	 */
	update: function (count) {
		savedCruiseCount.display(count);
		savedCruiseCount.save(count);
	}
};