// effects for hiding and showing form labels when stacked on top of form elements

jQuery(document).ready(function ($) {
	//access url parameters 

	//hide form labels if pre-populated with data
	$('.label-stack > input, .label-stack > select, .label-stack > textarea').each(function () {
		if ($(this).val() !== '') {
			$(this).closest('.label-stack').addClass('populated');
		}
	});
	var inputValueCheck;
	function checkInputValue(inputField) {
		inputValueCheck = setInterval(function(){ 
			if (inputField.val() !== '') {
				inputField.closest('.label-stack').addClass('populated');
				clearInterval(inputValueCheck);
			}
		},100);		
	}
	$(document).on('focusin','.label-stack > input, .label-stack > select, .label-stack > textarea', function() {
		$(this).closest('.label-stack').addClass('focused');
		if ($(this).is('input[type="text"]') && $(this).val() === '')  {
			checkInputValue($(this));
		}
	});
	$(document).on('focusout','.label-stack > input, .label-stack > select, .label-stack > textarea', function() {
		$(this).closest('.label-stack').removeClass('focused');
		if ($(this).val() == '') {
			$(this).closest('.label-stack').removeClass('populated');
		}
		if (inputValueCheck) {
			clearInterval(inputValueCheck);
		}
	});
	$(document).on('keydown','.label-stack > input, .label-stack > select, .label-stack > textarea', function() {
		if ($(this).val() !== '') {
			$(this).closest('.label-stack').addClass('populated');
		}
	});
	$(document).on('change','.label-stack > input, .label-stack > select, .label-stack > textarea', function() {
		if ($(this).val() !== '') {
			$(this).closest('.label-stack').addClass('populated');
		} else {
			$(this).closest('.label-stack').removeClass('populated');
		}
	});
	//end form effects
	
});//end document ready