/*
 * Action Toolbar Javascript
 */

//var addthis_share = {}



    var qltrcsId, actionToolbar = {
        /**
         * init - runs a series of checks on document ready
         * @param  {jQuery Object} $actionToolbar - the action toolbar container, holds all links and buttons for the toolbar
         */
        init: function ($actionToolbar) {
            var $actionToolbarBtn = $actionToolbar.find('#action-toolbar-btn'),
                actionToolbarDisplay = actionToolbar.getDisplaySetting(),
                $shareBtn = $actionToolbar.find('#action-toolbar-share-btn'),
                $chatLink = $actionToolbar.find('.action-toolbar-chat'),
                $brochureLink = $actionToolbar.find('.action-toolbar-brochure'),
                $feedbackify = $actionToolbar.find('.action-toolbar-feedback');

            // update display action toolbar to match value in session storage.
            // if session storage is true, we'll do nothing since the menu is display by default
            if (actionToolbarDisplay === 'false') {
                actionToolbar.hide($actionToolbarBtn);
            }
            else {
                actionToolbar.notifyStatusChange('isToolbarVisible', true);
            }

            // bind click event to button
            $actionToolbarBtn.on('click', function () {
                actionToolbar.displayBtn($(this));
            });

            // show / hide share menu for mobile users
            $shareBtn.on('click',function () {
                if ($shareBtn.attr('aria-expanded') === 'false') {
                    $shareBtn.attr('aria-expanded','true').closest('.action-toolbar-menu').addClass('action-toolbar-share-expanded');
                    actionToolbar.notifyStatusChange('isShareExpanded', true);
                } else {
                    $shareBtn.attr('aria-expanded','false').closest('.action-toolbar-menu').removeClass('action-toolbar-share-expanded');
                    actionToolbar.notifyStatusChange('isShareExpanded', false);
                }
                $shareBtn.trigger("blur");
            });

            // localize links
            if (typeof(localeData) == "undefined" || localeData == null) {
                $(document).on('getLocale', function () {
                    loclink.init($actionToolbar);
                    // brochure link
                    actionToolbar.updateBrochureLink($brochureLink,$actionToolbar,$shareBtn);

                });
            } else {
                loclink.init($actionToolbar);
                // brochure link
                actionToolbar.updateBrochureLink($brochureLink,$actionToolbar,$shareBtn);
            }

            // toggle state of $shareBtn based on viewport
            actionToolbar.toggleShareBtn($shareBtn);

            // update toggle state of $shareBtn on resize
            var hasResized = false;
            $(window).on('resize', function (e) {
                hasResized = true;
            });

            // Using setInterval to check the hasResized variable to determine if the browser has been resized. If so, check to see if we need to close the mobile menu
            setInterval(function () {
                if (hasResized) {
                    hasResized = false;
                    actionToolbar.toggleShareBtn($shareBtn);
                }
            }, 50);

            // chat link
            actionToolbar.chatLink($chatLink,$actionToolbar,$shareBtn);

            // facebook share link
            actionToolbar.facebookShareLink();

            // pinterest share link
            actionToolbar.pinterestShareLink();

            // Email share link
            actionToolbar.emailShareLink();

            // show action toolbar
            $actionToolbar.removeClass('hidden');

            // remove border from first action-toolbar-item if share is hidden
            actionToolbar.firstItemVisible($actionToolbar,$shareBtn);
            $feedbackify.on('feedbackify.visible',function () {
                actionToolbar.firstItemVisible($actionToolbar,$shareBtn);
            });

            $chatLink.on('clickToChat.updateDisplay',function () {
                actionToolbar.firstItemVisible($actionToolbar,$shareBtn);
            });

            
        },

        toggleShareBtn: function ($shareBtn) {
            if (Modernizr.mq('(min-width:48em)')) {
                $shareBtn.attr({
                    'aria-disabled': true,
                    'aria-hidden': true
                }).addClass('hidden').next('.action-toolbar-share-text').removeClass('hidden');
            } else {
                $shareBtn.attr({
                    'aria-disabled': false,
                    'aria-hidden': false
                }).removeClass('hidden').next('.action-toolbar-share-text').addClass('hidden');
            };
        },

        /**
         * hide - update the dom to hide the action toolbar
         * @param  {jQuery Object} $actionToolbarBtn - action toolbar show/hide button
         */
        hide: function ($actionToolbarBtn) {
            $actionToolbarBtn.attr('aria-expanded','false').prev('.action-toolbar-menu').addClass('action-toolbar-menu-lowered');
            actionToolbar.notifyStatusChange('isToolbarVisible', false);
        },

        /**
         * show - update the dom to show the action toolbar
         * @param  {jQuery Object} $actionToolbarBtn - action toolbar show/hide button
         */
        show: function ($actionToolbarBtn) {
            $actionToolbarBtn.attr('aria-expanded','true').prev('.action-toolbar-menu').removeClass('action-toolbar-menu-lowered');
            actionToolbar.notifyStatusChange('isToolbarVisible', true);
        },

        /**
         * displayBtn - tests to see if action toolbar needs to be show/hide based on aria-expanded status
         * @param  {jQuery Object} $actionToolbarBtn - action toolbar show/hide button
         */
        displayBtn: function ($actionToolbarBtn) {
            if ($actionToolbarBtn.attr('aria-expanded') === 'true') {
                actionToolbar.hide($actionToolbarBtn);
                actionToolbar.saveDisplaySetting(false);
            } else {
                actionToolbar.show($actionToolbarBtn);
                actionToolbar.saveDisplaySetting(true);
            }
            // remove focus after click
            $actionToolbarBtn.trigger("blur");
        },

        /**
         * saveDisplaySetting - saves current action toolbar display status in sessionStorage so that we can preserve display on next page load
         * @param  {boolean} status - true means action toolbar is visible, false it's hidden
         */
        saveDisplaySetting: function (status) {
            sessionStorage.setItem('actionToolbarDisplay', status);
        },

        /**
         * getDisplaySetting - looks up the current action toolbar display status in sessionStorage. If its null, we'll display the action toolbar by default
         * return {boolean} status - true means action toolbar is visible, false it's hidden
         */
        getDisplaySetting: function () {
            var status,
                actionToolbarDisplay = sessionStorage.getItem('actionToolbarDisplay')

            if (actionToolbarDisplay === null)  {
                status = true;
            } else {
                status = actionToolbarDisplay;
            }
            return status;
        },

        firstItemVisible: function ($actionToolbar,$shareBtn) {
            // remove border from first action-toolbar-item if share is hidden
            if ($shareBtn.is(':hidden')) {
                $actionToolbar.find('.action-toolbar-item').removeClass('first-visible').filter(':visible').first().addClass('first-visible');
            }
        },
        /**
         * updateBrochureLink - update brochure based on link in top navbar for users who are eligible for brochure orders
         * @param  {jQuery Object} $brochureLink - brochure link in action toolbar
         */
        updateBrochureLink: function ($brochureLink,$actionToolbar,$shareBtn) {
            if (localeData.brochures == 'true') {
                //show button and populate the url for the brochure link
                $brochureLink.removeClass('hidden');
                actionToolbar.firstItemVisible($actionToolbar,$shareBtn);
            }
        },

        /**
         * chatLink - show the chat link when the page is using Click to Chat
         * @param  {jQuery Object} $brochureLink - chat link in action toolbar
         */
        chatLink: function ($chatLink,$actionToolbar,$shareBtn) {
            $chatLink.on('clickToChat.updateDisplay', function () {
                if ($chatLink.find('.action-toolbar-chat-link').data('clickToChat') === 'Active') {
                    $chatLink.find('.action-toolbar-chat-link').addClass('chat-is-online');
                }
                $(this).removeClass('hidden');
                actionToolbar.firstItemVisible($actionToolbar,$shareBtn);
            });
        },
        /**
         * qltrFeedbackLink -Calling qualtrics for survey
         * @param  {string} qltrcsId - form id for the survey
         */
        qltrFeedbackLink: function(qltrcsId) {
            if (typeof qltrcsId == 'undefined' || qltrcsId == '') {
                qltrcsId = 'SV_7PcHxkLs8QHKhA9';
            }
            $('#action-toolbar-qualtrics-btn').attr("href", "https://princess.qualtrics.com/jfe/form/"+qltrcsId)
        },

        /**
         * facebookShareLink - generating facebook share link
         * @param  {jQuery Object} $facebookLink - facebook share element on the page
         */
        facebookShareLink: function () {
            var currentUrl = window.location;
            $('.action-toolbar-facebook-link').attr({"href":"https://www.facebook.com/sharer/sharer.php?u=" + currentUrl,
                                             "target":"_blank",
                                             "rel":"noopener"});
        },

         /**
         * pinterestShareLink - generating pinterest share link
         * @param  {jQuery Object} $facebookLink - facebook share element on the page
         */
        pinterestShareLink: function () {
            var currentUrl = window.location;
            var pageTitle = document.title;
            $('.action-toolbar-pinterest-link').attr({"href":"https://www.pinterest.com/pin/create/link/?url=" + currentUrl + "&description=" + pageTitle,
                                                     "target":"_blank",
                                                     "rel":"noopener"});
        },

         /**
         * emailShareLink - generating emailing share link
         * @param  {jQuery Object} $facebookLink - facebook share element on the page
         */
        emailShareLink: function () {
            var emailBody = "I saw this great information on the Princess website. I thought you might find it helpful. \n" + window.location;
            var emaiSubject = "I think you will love this great info from Princess.com.";
            $('.action-toolbar-email-link').attr({"href":"mailto:?subject="+emaiSubject+"&body="+encodeURIComponent(emailBody),
                                                "target":"_top",
                                                "rel":"noopener"});
        },

        /**
         * notifyStatusChange - sets the display status in ember object
         * @param  {string}  property - property name
         * @param  {boolean} status   - true means action toolbar is visible, false it's hidden
         */
        notifyStatusChange: function(property, status) {
            var obj = window['pclSearch'];

            if (obj) {
                obj.set(property, status);
            }
        }
    };

    jQuery(document).ready(function ($) {
        actionToolbar.init($('.action-toolbar'));
    });
