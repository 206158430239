/**
 * eglvchathandle - global variable to be used when egain window is opened
 * @type {object}
 */
var eglvchathandle = null;
var egainDockChat = egainDockChat  || {};
	var clickToChat = {
		/**
		 * $btn - chat buttons on the page
		 * @type {jQuery obj}
		 */

		$btn: $('.chat-btn, .action-toolbar-chat-link'),
		
		/**
		 * chatServerDetails - details about the chat service being used
		 * @type {jQuery obj}
		 * @param {url} url for initiating chat
		 * @param {source} is it egain or isaac
		 * @param {chatAvailUrl} is the url to check if chat is available
		 * @param {serviceReady} is the service already loaded
		 */
		chatServerDetails: {
			chatUrl: null,
			source: null,
			chatAvailUrl: null,
			serviceReady: false
		},

		/**
		 * [checkChatAvail] - ajax call to egain to see if reps are available for chat
		 * @param  {number}   entryPointId - id used to determine let egain know if user is signed in vs out
		 * @param  {dataType} return data type
		 * @return {xml/json}  - returns egain's xml or json for Isaac
		 */
		checkChatAvail: function(url, dataType) {
			return $.ajax({
				url: url,
				type: 'GET',
				dataType: dataType
			});
		},

		/**
		 * hideBtn - simple function to hide button row .navbar-btn-row is to hide button in nav, .chat-btn-row is a generic class to be used in content area of site
		 */
		hideBtn: function() {
			clickToChat.$btn.closest('.navbar-btn-row, .chat-btn-row, .action-toolbar-chat').addClass('hidden');
		},

		/**
		 * [targetInit] - triggered from Adobe Launch, if the chat has to be turned on, egain will be initialized for US & CA users only and Isaac for Australia and New zealand
            Isaac is triggered directly from the Global | Action Toolbar | Contact Us, Live Chat, Site Feedback | Direct Call #50 in adobe launch 
		 @param countryLocale - Country code of the web users
		 @param entryPointId  - For cruisepersonalizer use 1014, for a signed in user its 1010 and everyone else id 1001
	 */
		targetInit: function(chatService, template, entryPointId, isSignedIn) {
			if (chatService === 'EGAIN') {
				clickToChat.initEgain(entryPointId, template, isSignedIn);
			} else {
				clickToChat.hideBtn();
			}
		},
		
		/**
		 * initEgain - initialize egain chat service
		 *             1. test if user is signed in to determine egain template_name and entrypoint_id
		 *             2. test to see if rep is available for chat
		 *             3. update the display of the button based on availability, template and entry point ids
		 */
		initEgain: function(entrypPointId, template, isSignedIn) {
			if (window.docCookies && (docCookies.hasItem('egain-last-success-request-id') || docCookies.hasItem('EG-S-ID') ) ) {
				clickToChat.callEgainScript();
			}
			
			//add option of the domain, diff url for stage and uat.
			egainDockChat.serverURL = "https://princesscruises.egain.cloud/system";
			/*eGain Chat Entry Point*/
			egainDockChat.EntryPointId  = entrypPointId;
			/*eGain Chat Locale*/
			egainDockChat.Locale  = "en-US";
			 /*eGain template name*/
			 egainDockChat.Template = template ? template : "aqua"; 
			 /*Set to true to enable posting attributes to templates*/
			egainDockChat.PostChatAttributes = true; 
			egainDockChat.IntegratedEntryPoint  = "false";
			/*eGain video chat parameters */
			egainDockChat.VChatParams = '';
			 /*Set to true if custom button is used to launch docked chat */
			 egainDockChat.UseCustomButton=true;

			// target sending information if user is signed in or not
			
			// chat is always online, no need to check for availability
			clickToChat.updateBtnDisplay(true);
			clickToChat.updateBtnClick();
		},
		/**
		 * initIsaac - initialize isaac chat service
		 *             1. test if user is signed in to determine egain template_name and entrypoint_id
		 *             2. test to see if rep is available for chat
		 *             3. update the display of the button based on availability, template and entry point ids
		 */
		initIsaac: function () {
			var isaacAvail,
			chatEnv = hostDomain[sub_domain_substring].envir;
			if(chatEnv === "dev"){
				clickToChat.chatServerDetails.chatUrl = "https://test-isaac.wlcl.com.au/Chat/DirectChat";
				clickToChat.chatServerDetails.chatAvailUrl = "https://test-isaac.wlcl.com.au/Chat/IsExternalCustomerAlive";
			}else{
				clickToChat.chatServerDetails.chatUrl = "https://isaac.wlcl.com.au/Chat/DirectChat";
				clickToChat.chatServerDetails.chatAvailUrl = "https://isaac.wlcl.com.au/Chat/IsExternalCustomerAlive";
			}
			clickToChat.chatServerDetails.source = "isaac";
				$.when(
					clickToChat.checkChatAvail(clickToChat.chatServerDetails.chatAvailUrl, "json")
				).then(function (results) {
					if(results.chatServiceStatus == "online"){
						isaacAvail = true;
						clickToChat.updateBtnDisplay(isaacAvail);
						clickToChat.updateBtnClick();
					}else {
						isaacAvail = false;
						clickToChat.updateBtnDisplay(isaacAvail);
						clickToChat.updateBtnClick();
					}
				},function (req,error) {
					if (error === 'error') {
						error = req.statusText;
					}
					isaacAvail = false;
					clickToChat.updateBtnDisplay(isaacAvail);
					clickToChat.updateBtnClick();
					
				});

		},

		/**
		 * chatUrl -  constructs the url and popup window for egain chat service
		 * @param  {string} template_name - possible option: princessauth vs princess
		 * @param  {number} entrypoint_id - possible option:         1010 vs 1001
		 * @return {string}               - url string for click to chat button
		 */
		chatUrl: function () {
			/* server name,template name and entrypoint Id will going to change as per configuration.
			   Server name refers to the Web server where eGain has been installed.
			   Template name can be Onesource/oneSourceCunard.
			   Entrypoint Id is the starting point of chat.
			   It is configured in eGain and change as per the destination Queue
			*/
			var refererName = "";
			var chatUrl = "";
			refererName = encodeURIComponent(refererName);
			var refererurl = encodeURIComponent(document.location.href);
			var hashIndex = refererurl.lastIndexOf('#');
			if(hashIndex != -1){
				refererurl = refererurl.substring(0,hashIndex);
			}
			var eglvcaseid = (/eglvcaseid=[0-9]*/gi).exec(window.location.search);
			if (typeof EGAINCLOUD != "undefined" && EGAINCLOUD.Account.getAllIds) {
				var ids = EGAINCLOUD.Account.getAllIds();
				clickToChat.setCustomerParameters('aId',ids.a);
				clickToChat.setCustomerParameters('sId',ids.s);
				clickToChat.setCustomerParameters('uId',ids.u);
			}
			var EG_CALL_Q = window.EG_CALL_Q || [];
			EG_CALL_Q.push( ["enableTracker", true] );

			// create url for chat popup for isaac. Add any additional parameters here
			if (clickToChat.chatServerDetails.source === "isaac") {
				chatUrl = clickToChat.chatServerDetails.chatUrl;
			}
			else if (clickToChat.chatServerDetails.source === "egain") {
				clickToChat.setCustomerParameters('eglvrefname',refererName);
				clickToChat.setCustomerParameters('eglvcaseid',eglvcaseid);
				if (guestContactInfo !== undefined) {
					clickToChat.setCustomerParameters('fieldname_1',encodeURIComponent(guestContactInfo.greeting));
					clickToChat.setCustomerParameters('fieldname_2',encodeURIComponent(guestContactInfo.email));
				}
				chatUrl = "";
			}

			// return the dynamic url
			return chatUrl;
		},

		/**
		 * updateBtnDisplay - update the display of chat buttons in page based on rep availability
		 * @param  {boolean} chatAvail    - when true reps are available, false no reps available
		 */
		updateBtnDisplay: function(chatAvail) {
			clickToChat.$btn.each(function () {
				var $chatBtn = $(this),
				    $chatBtnText = $chatBtn.find('.chat-btn-text'),
				    chatBtnText;
				if (chatAvail) {
					// use online-text in markup if available, gives applications the flexibility to label button
					if ($chatBtnText.data('onlineText') === undefined) {
						chatBtnText = 'Live Chat';
					} else {
						chatBtnText = $chatBtnText.data('onlineText');
					}
					// update chat button data attributes and remove deactive class
					$chatBtn.data({'clickToChat':'Active','usText':chatBtnText});
					// for the action-toolbar

					if ($chatBtn.hasClass('action-toolbar-chat-link')) {
						$chatBtn.trigger('clickToChat.updateDisplay');
					} else {
						$chatBtn.removeClass('deactive');
					}
				} else {
					// use offline-text in markup if available, gives applications the flexibility to label button
					if ($chatBtnText.data('offlineText') === undefined) {
						chatBtnText = 'Chat is Offline';
					} else {
						chatBtnText = $chatBtnText.data('offlineText');
					}
					// update chat button data attributes and add deactive class
					$chatBtn.data({'clickToChat':'Inactive','usText':chatBtnText});
					// for the action-toolbar
					if ($chatBtn.hasClass('action-toolbar-chat-link')) {
						$chatBtn.trigger('clickToChat.updateDisplay');
					} else {
						$chatBtn.addClass('deactive');
					}
					// update text that is being displayed
					// for the action-toolbar
					if ($chatBtn.hasClass('action-toolbar-chat-link')) {
						$chatBtnText.html('Chat <span class="action-toolbar-secondary-text">' + 'is offline' + '</span>');
					} else {
						$chatBtnText.text(chatBtnText);
					}
				}
			});
		},
        activateChat: function () { 
			$.when(clickToChat.callEgainScript()).then(function () {
				// set the dynamic url for egain
				var chatUrl = clickToChat.chatUrl();
				if (egainDockChat && Object.keys(egainDockChat).length > 0) {
					egainDockChat.IsChatLaunched = true;
					if (!egainDockChat.launchChat) {
						egainDockChat.CallQueue = egainDockChat.CallQueue || [];
						egainDockChat.CallQueue.push({ name: 'launchChat', args: [] });
				    } else {
					    egainDockChat.launchChat();
				    }
				} else {
				    try {
				        if (eglvchathandle != null && eglvchathandle.closed == false) {
					        eglvchathandle.focus();
					        return;
				        }
				    } catch (err) {}
				    // set popup window settings and open it
				    var params = 'height=623,width=600,resizable=yes,scrollbars=yes,toolbar=no';
				    // popup window
				    eglvchathandle = window.open(chatUrl, '', params);
				}
			});
		},
		updateBtnClick: function() {
			// load egain and then popup on click of chat-btn
			clickToChat.$btn.on('click',function (e) {
				e.preventDefault();
				clickToChat.activateChat();
			});
            window.addEventListener('actionToolbarClick', function () {
				clickToChat.activateChat();
			});
		},
		//Method to set customer Parameters. To be called by client website. All the parameters specified in application-chat-defaults must be set here.
		setCustomerParameters: function (egainAttributeName, attributeValue) {
			if(!egainDockChat.SetParameter){
				egainDockChat.CallQueue = egainDockChat.CallQueue || [];
				egainDockChat.CallQueue.push({name:'SetParameter', args:[egainAttributeName,attributeValue]});
			}else{
				egainDockChat.SetParameter(egainAttributeName,attributeValue);
			}
		},

		callEgainScript: function(){
			if (clickToChat.chatServerDetails.serviceReady) {
				return true;
			} else {
				var EG_ACT_ID = "EG60139150";
				return (function(e, f) {
					var d, c, b, a = e.createElement("iframe");
					a.src = "about:blank";
					a.title = "";
					a.id = "egot_iframe";
					(a.frameElement || a).style.cssText = "width:0;height:0;border:0";
					b = e.getElementsByTagName("script");
					b = b[b.length - 1];
					b.parentNode.insertBefore(a, b);
					try {
						c = a.contentWindow.document
					} catch (g) {
						d = e.domain, a.src = "javascript:var d=document.open();d.domain='" + d + "';void(0);", c = a.contentWindow.document
					}
					c.open()._d = function() {
						var a = this.createElement("script");
						d && (this.domain = d);
						a.src = f;
						this.isEGFIF = !0;
						this.body.appendChild(a)
					};
					c.write('<body onload="document._d();">');
					c.close();
					clickToChat.chatServerDetails.serviceReady = true;
					return true;
				})(document, "//analytics.analytics-egain.com/onetag/" + EG_ACT_ID);
			}
		}
	};