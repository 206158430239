(function ($) {
	$.fn.tabNav = function (options) {
		// default settings
		var settings = $.extend({
			$tabNavMenu: this,
			tabNavWrapper: '.tab-nav-wrapper',
			tabNavRow: '.tab-nav-row',
			tabNavMenu: '.tab-nav-menu',
			tabNavItem: '.tab-nav-item',
			hasSubnavMenu: '.has-subnav-menu',
			tabSubnavWrapper: '.tab-subnav-wrapper',
			tabSubnavMenu: '.tab-subnav-menu',
			tabSubnavItem: '.tab-subnav-item',
			tabNavLinks: '.tab-nav-link',
			tabNavScroll: '.tab-nav-scroll',
			expandedClass: 'expanded',
			activeClass: 'active',
			scrollLeft: 'scroll-nav-left',
			scrollRight: 'scroll-nav-right',
			$container: this.closest('#container'),
			debug: false
		}, options);

		if (settings.debug === 'true') {
			console.log('options:', settings);
		}

		/* private functions stuff goes here */
		var tabNav = {
			// lets getting started
			init: function () {
				// handle each tabnav independently from each other
				settings.$tabNavMenu.each(function () {
					var $tabNavMenu = $(this);

					// first, check to see if the current $tabNavMenu has been initiated. If so, we ignore to avoid duplicate initiazationn and all kinds of other headaches
					if ($tabNavMenu.data('status') === undefined || $tabNavMenu.data('status') === 'not-ready') {
						// set the status to pending, then get to work...
						$tabNavMenu.data('status','pending');
						console.log('tabnav test: init this tabnav and mark the status as pending...');

						var $tabNavWrapper = $tabNavMenu.closest(settings.tabNavWrapper),
						    $tabNavRow = $tabNavMenu.closest(settings.tabNavRow),
						    $tabNavScrollBtn = $tabNavMenu.closest(settings.tabNavWrapper).find(settings.tabNavScroll),
						    $container = settings.$container,
						    tabNavCounter = 0;

						// we have to set this interval to wait for p13n to load the tabs for sections like Come Back New
						var tabNavCheck = setInterval(function() {
							var $tabNavLinks = $tabNavMenu.find(settings.tabNavLinks),
							    $hasSubnavMenu = $tabNavLinks.filter(settings.hasSubnavMenu),
							    tabNavLinksLength = $tabNavLinks.length;
							    
							console.log('tabNavLinksLength',tabNavLinksLength);
							if (tabNavLinksLength > 0) {
								// clear interval
								clearInterval(tabNavCheck);
								tabNavCheck = false;

								// check if link needs to be marked as active on document ready / load
								if ($tabNavMenu.data('setactive') !== undefined && $tabNavMenu.data('setactive') === true) {
									tabNav.activeLink($tabNavLinks, settings.tabSubnavWrapper, settings.hasSubnavMenu, settings.activeClass);
								}

								// check if easytabs needs to be init
								if ($tabNavMenu.data('easytabs') !== undefined && $tabNavMenu.data('easytabs') === true) {
									tabNav.initEasytabs($container, $tabNavLinks, settings.tabNavItem);
								}

								// attach events tab nav links
								$tabNavLinks.on('click', function () {
									var $link = $(this);
									tabNav.activeLink($tabNavLinks, settings.tabSubnavWrapper, settings.hasSubnavMenu, settings.activeClass, $link);
								});

								// begin checking if tab nav needs to be scrollable
								tabNav.checkMenuFit($tabNavMenu, $tabNavRow, $tabNavScrollBtn);

								// attach events to scroll buttons
								tabNav.scrollEvents($tabNavScrollBtn, $tabNavMenu, settings.scrollLeft, settings.scrollRight);

								// add click events
								tabNav.clickEvents($tabNavMenu, settings.tabNavItem + ' > ' + settings.tabNavLinks, $hasSubnavMenu);

								// set tab-subnav-menu links to not be focusable
								$tabNavMenu.find('.tab-subnav-item > .tab-nav-link').attr('tabindex','-1');

								// make tabnav visible and mark ready
								$tabNavMenu.removeClass('invisible').data('status','ready');

								// hide the entire tabnav from view when there is only 1 link
								if (tabNavLinksLength < 2 && ($tabNavMenu.data('alwaysvisible') === undefined || $tabNavMenu.data('alwaysvisible') === false)) {
									$tabNavWrapper.addClass('hidden');
								}
							}
						}, 50);

					} else {
						console.log('tabnav test: dont init this tabnav, its already been started');
					}
				});// end each loop for tabNavMenu
			},

			checkMenuFit: function ($tabNavMenu, $tabNavRow, $tabNavScrollBtn) {
				var checkMenuFit = true;
				$(window).on('resize', function (e) {
					checkMenuFit = true;
				});
				window.addEventListener('orientationchange', function () {
					checkMenuFit = true;
				});
				// Using setInterval to check the hasResized variable to determine if the browser has been resized. If so, check to see if we need to close the mobile menu
				var fitInterval = setInterval(function () {
					if (checkMenuFit) {
						checkMenuFit = false;
						tabNav.displayScrollBtn($tabNavMenu, $tabNavRow, $tabNavScrollBtn);
					}
				}, 50);
			},

			scrollEvents: function ($tabNavScrollBtn, $tabNavMenu, scrollLeft, scrollRight) {
				var scrollBtnPressed = false,
				    $scrollBtn;

				//$tabNavMenu.hammer().on('swipe')
				// determine the direction and let the setInterval know the scroll button is pressed
				$tabNavScrollBtn.on('mousedown', function () {
					$scrollBtn = $(this);
					scrollBtnPressed = true;
				});
				// Using touch event plugin hammer.js to attach native touch events to navbarDropdownToggle
				// Use preventDefault() inside touch event handlers, so the default mouse-emulation handling does not occur - http://www.html5rocks.com/en/mobile/touchandmouse/
				$tabNavScrollBtn.hammer().on('touchstart', function (e) {
					e.preventDefault();
					$scrollBtn = $(this);
					scrollBtnPressed = true;
				});
				// let the setInterval know the scroll button is not pressed
				$tabNavScrollBtn.on('mouseup', function () {
					scrollBtnPressed = false;
					// remove focus state from button
					$(this).blur();
				});
				// let the setInterval know the scroll button is not pressed
				$tabNavScrollBtn.hammer().on('touchend', function (e) {
					e.preventDefault();
					scrollBtnPressed = false;
					$(this).blur();
				});
				// Using setInterval to check if the scroll buttons are pressed down, continue to move the menu as long as buttons are pressed
				var scrollInterval = setInterval(function () {
					if (scrollBtnPressed) {
						var direction = (($scrollBtn.hasClass(settings.scrollLeft)) ? 10 : -10);
						tabNav.moveMenu($tabNavMenu, direction);
					}
				}, 50);
				// bind pan/swipe for moving the nav on a tablet or touch display
				$tabNavMenu.hammer().on('panstart panmove panend pancancel', function (e) {
					tabNav.moveMenu($tabNavMenu, e.gesture.deltaX);
				});
			},

			moveMenu: function ($tabNavMenu, direction) {
				var currentPosition = (($tabNavMenu.data('offset') === undefined) ? 0 : $tabNavMenu.data('offset')),
					newPosition;

				if (direction < 0) {
					var maxOffset = $tabNavMenu.data('max-offset');
					// when scrolling to the left, stop if it's less that the maxOffset
					if (currentPosition + direction > maxOffset) {
						newPosition = currentPosition + direction;
					} else {
						newPosition = maxOffset;
					}
				} else {
					// when scrolling to the right, stop if its greater than zero
					if (currentPosition + direction < 0) {
						newPosition = currentPosition + direction;
					} else {
						newPosition = 0;
					}
				}
				
				$tabNavMenu.css('margin-left', newPosition).data('offset', newPosition);

			},

			displayScrollBtn: function ($tabNavMenu, $tabNavRow, $tabNavScrollBtn) {
				var display;
				if ( Modernizr.mq('(min-width:48em)') && ($tabNavMenu.width() > $tabNavRow.width()) ) {
					$tabNavScrollBtn.removeClass('hidden');
					$tabNavRow.addClass('scrollable');
					$tabNavMenu.addClass('scrollable').data('max-offset', $tabNavRow.width() - $tabNavMenu.width());
					display = true;
				} else {
					$tabNavScrollBtn.addClass('hidden');
					$tabNavRow.removeClass('scrollable');
					$tabNavMenu.removeClass('scrollable').css('margin-left','auto').data('offset',0);
					display = false;
				}
				return display;
			},
			
			activeLink: function ($tabNavLinks, tabSubnavWrapper, hasSubnavMenu, activeClass, $activeLink) {
				// if $activeLink is undefined, find the link in the tab nav that has a matching url in the href
				if ($activeLink === undefined) {
					var $activeLink,
					    pathname = window.location.pathname;

					// clean up inconsistent use of index file name in urls
					pathname = pathname.split('index.')[0];

					$tabNavLinks.each(function () {
						var href = $(this).attr('href');

						//clean up inconsistent use of index file name in links
						href = href.split('index.')[0];
                        // checks to see if book url is used, then removes domain
                        href = href.indexOf('http') === -1 ? href : href.split('.com')[1];
                        
						if (href === pathname) {
							$activeLink = $(this);
							$activeLink.addClass(activeClass);
							// if $activeLink is in a tab-subnav-wrapper, mark the parent link as active too
							if ($activeLink.closest(tabSubnavWrapper).length > 0) {
								$activeLink.closest(tabSubnavWrapper).prev(hasSubnavMenu).addClass(activeClass);
							}
						}
					});
				} else {
					$activeLink.addClass(activeClass);
					// if $activeLink is in a tab-subnav-wrapper, mark the parent link as active too
					if ($activeLink.closest(tabSubnavWrapper).length > 0) {
						$activeLink.closest(tabSubnavWrapper).prev(hasSubnavMenu).addClass(activeClass);
					}
					// toggle aria-selected for tab panels
					if ($tabNavLinks.filter('[aria-selected]').length) {
						$tabNavLinks.attr('aria-selected', false);
						$tabNavLinks.removeClass(activeClass);
						$activeLink.attr('aria-selected', true);
						$activeLink.addClass(activeClass);
					}
				}
			},

			
			initEasytabs: function ($container, $tabNavLinks, tabNavItem) {
				if (typeof $.easytabs === 'function') {
					tabNav.activateEasytabs($container, $tabNavLinks, tabNavItem);
				} else {
					tabNav.loadEasytab($container, $tabNavLinks, tabNavItem);
				}
			},

			loadEasytab: function ($container, $tabNavLinks, tabNavItem) {
				// load files async
				if (typeof loadJS === 'function') {
					loadJS('/js/global/jquery/plugins/easytabs/jquery.easytabs.min.js', function () {
						tabNav.activateEasytabs($container, $tabNavLinks, tabNavItem);
					});
				}
			},

			activateEasytabs: function ($container, $tabNavLinks, tabNavItem) {
				//initiate easytabs
				$container.easytabs({
					tabs: tabNavItem,
					updateHash: false,
					transitionIn: 'fadeIn',
					transitionOut: 'fadeOut'
				});

				// add aria attribute on init
				$tabNavLinks.filter('.active').attr('aria-selected', true);

				//quick jump link to click on an easy tab. link in jump link must match the url (not including the hash tag) in the .drop-tab > .tab
				if ($container.find('.easy-tab-jump')) {
					$container.on('click', '.easy-tab-jump', function (e) {
						e.preventDefault();

						var $jumpLink = $(this);
							jumpLinkTarget = $jumpLink.attr('href');

						$tabNavLinks.each(function () {
							var target = $(this).attr('href').split(' ');
							for (var i=0; i < target.length; i++) {
								if (target[i] === jumpLinkTarget) {
									$tabLink.trigger('click');
								}
							}
						});
						$('html,body').stop().animate({scrollTop: ($container.offset().top)-20 }, 500);
					});
				}

				$.fn.tabNav.easytabsCallbacks($container);
			},

			expandDropdown: function ($tabNavLink) {
				$tabNavLink.closest('.tab-nav-item').addClass('expanded').find('.tab-subnav-item > .tab-nav-link').attr('tabindex','0');
				if ($tabNavLink.hasClass('has-subnav-menu')) {
					$tabNavLink.attr('aria-expanded','true');	
				}
			},

			hideDropdown: function ($tabNavLink) {
				$tabNavLink.closest('.tab-nav-item').removeClass('expanded').find('.tab-subnav-item > .tab-nav-link').attr('tabindex','-1');
				$tabNavLink.removeClass('active');
				if ($tabNavLink.hasClass('has-subnav-menu')) {
					$tabNavLink.attr('aria-expanded','false');
					$tabNavLink.siblings().find('a').each(function () {
						let href = $(this).attr('href');
						href = href.split('index.')[0];
						href = href.indexOf('http') === -1 ? href : href.split('.com')[1];
						if(window.location.pathname === href) {
							$tabNavLink.addClass('active');
						}
					})
				}
			},

			hideAllDropdowns: function () {
				var $expandedDropdowns = $('.tab-nav-item.expanded');
				if ($expandedDropdowns.length > 0) {
					tabNav.hideDropdown($expandedDropdowns.find('.tab-nav-link'));
				}
			},

			toggleDropdown: function ($tabNavSubnavMenu,eventType) {
				if ( (eventType === 'touch' || eventType === 'click') && $tabNavSubnavMenu.prop('ariaExpanded') === 'true') {
					tabNav.hideDropdown($tabNavSubnavMenu);	
				} else {
					tabNav.hideAllDropdowns();
					tabNav.expandDropdown($tabNavSubnavMenu);
				}
			},
								  
			clickEvents: function ($tabNavMenu, topLevelTabNavLinks, $hasSubnavMenu) {
				// only initiate when tabnav has a sub-menu
				if ($hasSubnavMenu.length > 0) {
					// Use preventDefault() inside touch event handlers, so the default mouse-emulation handling does not occur - http://www.html5rocks.com/en/mobile/touchandmouse/
					$hasSubnavMenu.on('touchend', function (e) {
					  	e.preventDefault();
					});

					$hasSubnavMenu.on('touchstart click', function (e) {
						e.preventDefault();
						tabNav.toggleDropdown($(this),'click');
					});

					// Toggle tabnav dropdown on click with keyboard
					$hasSubnavMenu.on('keydown', function (e) {
						if (e.which === 13 || e.which === 32 ) {
							e.preventDefault();
							tabNav.toggleDropdown($(this),'click');
						}
					});

					$(document).on('keydown touchstart click',function (e) {
						/* escape out of menu down for keyboard controls */
						if (e.which === 27 ) {
							tabNav.hideAllDropdowns();
						}
						if (e.type === 'click' && !$(e.target).closest('.tab-nav-item').length) {
							tabNav.toggleDropdown( $hasSubnavMenu.filter('[aria-expanded="true"]') ,'click');
						}
					});
				}
			}
		}

		// initiate here...
		tabNav.init();

		// return element for chainability
		return this;

	}// end $.fn.tabNav

	/* public functions stuff goes here */
	$.fn.tabNav.easytabsCallbacks = function (settings) {};

})(jQuery);