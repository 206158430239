/**
 * Script which tracks URL history and attaches previousURL and refresh to the digital data layer to be utilized
 * by analytics and whatever else needs it.
 */
$(document).ready(function() {
    //  Function which updates URLHistory, is attached to various browser history event function overrides below.
    function updateURLHistory() {
        // We check if previous-url exists in the session or if document.referrer (unreliable inside SPAs) can tell us where user has come from inside Princess.
        var previousURL = window.sessionStorage.getItem('previous-url') || document.referrer;
        // If previousURL exists, we can add it to the DDL here.
        if (window.digitalDataHelper && previousURL) {
            window.digitalDataHelper.addData('page', 'previousURL', previousURL);
        }

        // We also check to see if user has refreshed, and attach that to the datalayer.
        if (window.digitalDataHelper) {
            window.digitalDataHelper.addData('page', 'refresh', previousURL === window.location.href);
        }

        // Now that the URL was set from the session, we can update it with the current URL, which will then be used in the next navigation.
        sessionStorage.setItem('previous-url', window.location.href);
    }

    // Run updateURLHistory on initial load
    updateURLHistory();

    // Intercept history API methods, overriding them with our new function.
    var pushState = history.pushState;
    var replaceState = history.replaceState;

    // Handles SPA router functions
    history.pushState = function() {
        pushState.apply(history, arguments);
        updateURLHistory();
    };
    // Handles back/forward browser buttons
    history.replaceState = function() {
        replaceState.apply(history, arguments);
        updateURLHistory();
    };
});