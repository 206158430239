/*
 * Audio Description
 */
$(function() {
    // initialize on doc ready
    audioDesc.check();
    // initialize on lightbox open
    $('body').on('mfpAjaxContentAdded', function (e) {
        audioDesc.check();
    });
});

var audioDesc = {
    /**
     * [check] checks to see if video container exists
     */
    check: function() {
        var $container = $('.video-accessible');
        if ($container.length > 0) {
            audioDesc.init();
        }
    },
    /**
     * [init] initializes events
     * @param  {Object} $video [video container]
     */
    init: function($video) {
        $video = $video || $('.video-accessible');

        $video.each(function() {
            var $container = $(this),
                $vidWrapper = $container.find('.video-responsive'),
                $iframe = $container.find('iframe'),
                title = $iframe.attr('title'),
                $radio = $container.find('.radio-toggle input'),
                $hiddenSpan = $container.find('.radio-toggle span.visuallyhidden'),
                message = 'Turn audio description for ' + title;

            // change visually hidden text
            $hiddenSpan.text(message);
            // radio toggle click event
            $radio.click(function() {
                var src = $iframe.attr('src'),
                    srcAd = $iframe.attr('data-ad-src'),
                    inputId = $(this).attr('id'),
                    $radioWrapper = $(this).closest('.radio-toggle'),
                    shouldEnable = inputId.indexOf('ad-enabled') > -1;

                // set data attribute for regular url if it doesn't exist
                audioDesc.setDataAttr($iframe, src);
                // swap iframe source
                audioDesc.toggleSrc($radioWrapper, $iframe, src, srcAd, shouldEnable);
                // set focus on video wrapper
                $vidWrapper.attr('tabindex','0').focus();
            });
        });
    },
    /**
     * [toggleSrc] toggles source of iframe
     * @param  {Object}  $radioWrapper [span wrapper of radio input]
     * @param  {Object}  $iframe       [youtube iframe]
     * @param  {String}  src           [video source]
     * @param  {String}  srcAd         [audio description src]
     * @param  {Boolean} shouldEnable  [describes radio action]
     */
    toggleSrc: function($radioWrapper, $iframe, src, srcAd, shouldEnable) {
        var $sibling = $radioWrapper.siblings().find('input'),
            swapSrc = shouldEnable ? srcAd : $iframe.data('src');

        // check to see if wrapper has active class to prevent unnecessary swapping
        if (!$radioWrapper.hasClass('active')) {
            // swap iframe source
            $iframe.attr('src', swapSrc);
            // uncheck other radio button
            $sibling.prop('checked', false);
            // swap active classes
            $radioWrapper.addClass('active').siblings().removeClass('active');
        }
    },
    /**
     * [setDataAttr] sets src data attribute on iframe; we need this for swapping between regular and ad video sources
     * @param  {Object} $iframe [youtube iframe]
     * @param  {String} src     [video source]
     */
    setDataAttr: function($iframe, src) {
        if (!$iframe.data('src')) {
            $iframe.data('src', src);
        }
    }
}
