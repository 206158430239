/* 	Custom Code for Princess.com Lightbox*/
/* Version 1.2 */

function lightbox_show_hide(){
	lightBoxoverlay.show('fast', function(){
		lightBox.fadeIn('medium');
	});
	//To hide the lightbox on clicking close window button
	jQuery(document).on('click','.lightbox-close-window > .close-link, .lightbox-close-window-top > .close-btn, .close-lightbox',hideLightbox);
	jQuery(document).on('click','#lightbox-overlay', hideLightbox);
	if ( !jQuery('.lightbox-close-window').is(':visible') && !jQuery('.lightbox-close-window-top').is(':visible') ) {
		jQuery(document).off('click','#lightbox-overlay', hideLightbox);
	}
	// move focus to lightbox
	$('.lightbox').attr('tabindex', -1).trigger('focus');
}

function hideLightbox() {
	jQuery('#lightbox-container').trigger('close-lightbox').remove();
	jQuery('#container object').show();
	return false;
}

jQuery(document).on('click','.lightbox-close-window',function() {
	return false;
});

function openLightbox(url, disableTopBtn, disableBottomBtn, adjContainer, lightboxTpl, alt) {
	url = DOMPurify.sanitize(url);

	buildLightboxContainer(adjContainer);

	//Inline content trumps all
	if( url.trim().indexOf('#') === 0 ){
		if (typeof lightboxTpl === 'undefined' || lightboxTpl === '') {
			lightBox.append(jQuery(url).html());
		} else {
			lightBox.append(lightboxTpl);
			lightBox.find('#popupContent').append(jQuery(url).html());
		}
		buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
		lightbox_show_hide();
	}
	else{

		if( typeof targetElement != 'undefined' ){
			url= url+ " "+targetElement;
		}

		var extension = url.substr( (url.lastIndexOf('.') +1) );
	    switch(extension) {
	        case 'jpg':
	        case 'png':
	        case 'gif':
				jQuery('<img>').on('load',function() {
					//lightBox.prepend(this).css('padding-top','20px');
					lightBox.addClass('no-min-width');
					lightBox.prepend(this);
					buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
					lightbox_show_hide();
				})
				.attr('src', url)
				.attr('alt', alt);
				break;
			default:
				if (typeof lightboxTpl == 'undefined' || lightboxTpl == '') {
					lightBox.load(url, function () {
						if(lightBox.hasClass("ezair-terms")){
						  var lightBoxPos = ezAirTermsPos.top - 600;
						  lightBox.css("margin-top",lightBoxPos);
						}
						buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
						lightbox_show_hide();
						createAddThis();
					});
				} else {
					lightBox.append(lightboxTpl);
					lightBox.find('#popupContent').load(url, function () {
						buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
						lightbox_show_hide();
						createAddThis();
					});
				}
		}
	}
}

function createAddThis(){
	if (window.addthis){
		addthis.toolbox('.addthis_toolbox');
		addthis.counter('.addthis_counter');
	}
	else{
		jQuery.getScript('//s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4fc673615553b316#async=1');
	}
}

function openIframe(iframeCustom,disableTopBtn,disableBottomBtn,adjContainer){
	var $iframe = jQuery("<iframe id='iframe-popup-page' src='"+url+"' width='"+iframeWidth+"'  height='"+iframeHeight+"' frameBorder='0' onLoad='openIframeLinksInParentWindow()' /> ");
	buildLightboxContainer(adjContainer);
	buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
	lightBox.addClass('iframe-lightbox').css('width',iframeWidth);
	if (iframeCustom != '') {
		lightBox.addClass(iframeCustom);
	}
	$iframe.prependTo("#lightbox-container .lightbox");
	lightbox_show_hide();
}

function openIframeBox(urls, iWidth, iHeight, adjContainer, disableTopBtn,disableBottomBtn){
	//erase the error msg on popup load
	jQuery('#showError').html('');
    var $iframe = jQuery("<iframe id='iframe-popup-page' src='"+urls+"' width='"+iWidth+"'  height='"+iHeight+"' frameBorder='0' onLoad='openIframeLinksInParentWindow()' /> ");
    buildLightboxContainer(adjContainer);
    buildLightBoxCloseElements(disableTopBtn,disableBottomBtn);
    lightBox.addClass('iframe-lightbox');
    $iframe.prependTo("#lightbox-container .lightbox");
    lightbox_show_hide();
    jQuery('iframe').iframeAutoHeight({heightOffset: 0});// iframe dynamic height
    return false;
}

function loadInsideLightbox(){
	lightBox.load(url, function () {
		buildLightBoxCloseElements();
		lightbox_show_hide();
	});
}

jQuery(document).on('click','.open-iframe, .iframe, .pcfb',function(){ // Open iframe inside Lightbox
	iframeWidth = 734;
	iframeHeight = 0;
	url= jQuery(this).attr('href');
	if (jQuery(this).attr('data-width') !== undefined && jQuery(this).attr('data-width') !==""){
		iframeWidth= jQuery(this).attr('data-width');
	} else{
		iframeWidth = 734;
	}
	var iframeAutoHeight = true;
	if (jQuery(this).attr('data-height') !== undefined) {
		iframeHeight= jQuery(this).attr('data-height');
		iframeAutoHeight = false;
	} else {
		iframeHeight = 600;
	}

	//customize the iframe for select applications
	iframeCustom = '';
	if ( jQuery(this).hasClass('panorama') ) {
		iframeCustom = 'panorama';
	} else if ( jQuery(this).is('#special-offers-link') ) {
		iframeCustom = 'special-offers';
	} else if ( jQuery(this).hasClass('full-deck-plan') ) {
		iframeCustom = 'full-deck';
	}
	var adjContainer = '';
	if (jQuery(this).hasClass('no-min-width')) {
		adjContainer = 'no-min-width';
	} else if (jQuery(this).hasClass('full-width')) {
		adjContainer = 'full-width';
	} else if (jQuery(this).hasClass('shorex-video')) {
		adjContainer = 'shorex-video';
	}

	openIframe(iframeCustom, jQuery(this).hasClass('disableTopBtn'), jQuery(this).hasClass('disableBottomBtn'), adjContainer);

	if (iframeAutoHeight){
		jQuery('iframe').iframeAutoHeight({heightOffset: 0});// iframe dynamic height
	}

	return false;
});

function openIframeLinksInParentWindow(){
	 setTimeout( function() {
		//Force links inside iframe page to open in parent page excluding specific links (with class open-iframe, .open-lightbox and target attribute _blank).
		try{
			jQuery('#iframe-popup-page').contents().find('a:not(.same-window)').attr('target','_blank').addClass('new-window');
			jQuery('#iframe-popup-page').contents().find('a:not(.open-lightbox, .open-iframe, .new-window, .same-window)').attr('target','_parent');
		}
		catch(e){}
	 }, 1000 );
}

jQuery(document).on('click','.open-lightbox',function(){
	// using DOM Purify to sanitize the href used in the lightbox
	url = DOMPurify.sanitize(jQuery(this).attr('href'));

	//Code to load only the content from the specified container inside the page.
	targetElement = jQuery(this).attr('data-target');
	inlineContent = jQuery(this).attr('data-inline-id');

	var adjContainer = '';

	if (jQuery(this).hasClass('no-min-width')) {
		adjContainer = 'no-min-width';
	} else if (jQuery(this).hasClass('full-width')) {
		adjContainer = 'full-width';
	} else if (jQuery(this).hasClass('shorex-video')) {
		adjContainer = 'shorex-video';
	}

	var lightboxTpl = '';
	if (typeof jQuery(this).attr('data-lightbox-tpl') != 'undefined') {
		lightboxTpl = jQuery('<div/>', {
			html: jQuery('#'+jQuery(this).attr('data-lightbox-tpl')).html()
		});
		if (typeof jQuery(this).attr('data-lightbox-title') != 'undefined') {
			lightboxTpl.find('#popupTitleArea h2').text(jQuery(this).attr('data-lightbox-title'));
		} else {
			lightboxTpl.find('#popupTitleArea h2').text('&nbsp;');
		}
	}

	var alt = $(this).find('img').attr('alt');

	if (typeof inlineContent != 'undefined'){
		openLightbox(inlineContent,adjContainer);
	} else {
		if (jQuery('#lightbox-container').length >=1){
			loadInsideLightbox(url);
		} else {
			openLightbox(url, jQuery(this).hasClass('disableTopBtn'), jQuery(this).hasClass('disableBottomBtn'), adjContainer, lightboxTpl, alt);
		}
	}

	return false;
});

function buildLightboxContainer(adjContainer){
	jQuery('#lightbox-container').remove();
	jQuery('body').append('<div id="lightbox-container">');
	jQuery('#lightbox-container').prepend('<div class="lite-popup"><div id="lightbox-overlay"></div><div class="lightbox">');
	lightBox=jQuery('#lightbox-container .lightbox');
	lightBoxoverlay= jQuery('#lightbox-overlay');
	if (adjContainer !== '') {
		jQuery('.lightbox').addClass(adjContainer);
	}
}

function buildLightBoxCloseElements(disableTopBtn,disableBottomBtn){
	var closeElements = '';

	if (!disableBottomBtn) {
		closeElements += '<div class="lightbox-close-window"><button class="close-link">Close Window</button></div>';
	}
	if (!disableTopBtn) {
		closeElements += '<div class="lightbox-close-window-top"><span class="close-btn"><img src="/images/global/lightbox_close-btn.png" height="50" width="110" alt="Close Window"></span></div>';
	}
	jQuery('#lightbox-container .lightbox').append(closeElements);
}

/*
  Plugin: iframe autoheight jQuery Plugin
  Version: 1.7.1
  Description: when the page loads set the height of an iframe based on the height of its contents
  see README: http://github.com/house9/jquery-iframe-auto-height
*/
(function(a){a.fn.iframeAutoHeight=function(b){function d(a){c.debug&&c.debug===!0&&window.console&&console.log(a)}function e(b,c){d("Diagnostics from '"+c+"'");try{d("  "+a(b,window.top.document).contents().find("body")[0].scrollHeight+" for ...find('body')[0].scrollHeight"),d("  "+a(b.contentWindow.document).height()+" for ...contentWindow.document).height()"),d("  "+a(b.contentWindow.document.body).height()+" for ...contentWindow.document.body).height()")}catch(e){d("  unable to check in this state")}d("End diagnostics -> results vary by browser and when diagnostics are requested")}var c=a.extend({heightOffset:0,minHeight:0,callback:function(a){},animate:!1,debug:!1,diagnostics:!1},b);return d(c),this.each(function(){function f(b){c.diagnostics&&e(b,"resizeHeight");var f=a(b,window.top.document).contents().find("body"),g=f[0].scrollHeight+c.heightOffset;d(g),g<c.minHeight&&(d("new height is less than minHeight"),g=c.minHeight+c.heightOffset),d("New Height: "+g),c.animate?a(b).animate({height:g+"px"},{duration:500}):b.style.height=g+"px",c.callback.apply(a(b),[{newFrameHeight:g}])}var b=0;d(this),c.diagnostics&&e(this,"each iframe");if(a.browser.safari||a.browser.opera){d("browser is webkit or opera"),a(this).on('load',function(){var a=0,e=this,g=function(){f(e)};b===0?a=500:e.style.height=c.minHeight+"px",d("load delay: "+a),setTimeout(g,a),b++});var g=a(this).attr("src");a(this).attr("src",""),a(this).attr("src",g)}else a(this).on('load',function(){f(this)})})}})(jQuery);



/*
 * Magnific Popup
 */

/* Instructions

The following steps are required
* use class "open-mfp" to initialize
* to set the lightbox type, add one of the following classes: "mfp-inline", "mfp-ajax", "mfp-iframe"
* set the source of the lightbox in the href

The following steps are optional
* set data-mfp-tpl="true" if sourced content does not have the proper popup template markup
* set data-mfp-custom-tpl="#custom-tpl-id" if you want to use a customized popup template that is hidden somewhere on the web page
* use data-mfp-title="" to dynamically set the lightbox title
* use data-mfp-content="" to dynamically set the lightbox content

Ex 1 - simple ajax lightbox
<a href="ajax-test.html" class="open-mfp mfp-ajax">Open lightbox</a>

Ex 2 - complex inline lightbox using default template width dynamic title and content
<a href="#inline-test" class="open-mfp mfp-inline" data-mfp-tpl="true" data-mfp-title="Dynamic inline lightbox" data-mfp-content="<p>what does this mfp-content look like</p>">Open lightbox</a>

*/

(function ($) {
	$(document).ready(function () {

		$(document).on('click', '.mfp-close', function (e) { console.log('mfp-close', e); });

		/* need to check to see if the plug-in is available on the page. if not, load the required JS and CSS before initializing magnificPopup */
		if (typeof $.fn.magnificPopup == 'function') {
			mfpInit();
		} else {
			// to prevent js errors, test to confirm helper function loadCSS, onloadCSS, and loadJS are available
			if (typeof loadCSS == 'function' && typeof onloadCSS == 'function' && typeof loadJS == 'function') {
				var mfpCSSLoaded = false,
				    mfpJSLoaded = false,
				    loadMfpCSS = loadCSS('/css/global/magnific-popup/magnific-popup.css');

				onloadCSS(loadMfpCSS, function() {
					mfpCSSLoaded = true;
				});
				loadJS('/js/global/jquery/plugins/magnific-popup/jquery.magnific-popup.min.js', function () {
					mfpJSLoaded = true;
				});

				//check every 50ms to see if the file have loaded, then proceed with initializing the droptabs
				//if the files fail to load within 5s, stop checking
				var loadCounter = 0,
				    loadCheck = setInterval(function() {
					if (mfpCSSLoaded == true && mfpJSLoaded == true) {
						clearInterval(loadCheck);
						mfpInit();
					}
					if (loadCounter < 100) {
						loadCounter++;
					} else {
						if (loadCheck != false) {
							clearInterval(loadCheck);
						}
					}
				},50);
			}
		}


	});

	function mfpInit() {
		$('body').magnificPopup({
			delegate: '.open-mfp',
			removalDelay: 300,
			alignTop: true,
			modal: false,
			closeMarkup: '<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button class="mfp-close icon-no-text" data-test-id="mfp-close"><span class="visuallyhidden">Close Modal</span><svg><use href="#icon-close-x"></use></svg></button></div></div>',
			mainClass: 'border-box-sizing',
			overflowY: 'scroll',
			callbacks: {
				beforeOpen: function() {
                    $(document).on('click', '.open-mfp', function (e){
                        if (e.target.dataset.mfpModal) {
                            $.magnificPopup.instance.st.modal = true;
                            $.magnificPopup.instance.st.closeOnContentClick = false;
                            $.magnificPopup.instance.st.closeOnBgClick = false;
                            $.magnificPopup.instance.st.showCloseBtn = false;
                            $.magnificPopup.instance.st.enableEscapeKey = false;
                        }
                    });
				},
				elementParse: function(item) {
					if (item.type == 'inline') {
						//if the inline content is within a script tag, we need to grab the content for the lightbox
						if ($(item.src).is('script')) {
							item.src = $(item.src).html();
						}

						//Wrap results in default template
						if (typeof item.el.data('mfp-tpl') != 'undefined' && item.el.data('mfp-tpl') == true) {
							item.src = mfpTpl($(item.src).clone());
						}

						//Wrap results in custom template
						if (typeof item.el.data('mfp-custom-tpl') != 'undefined' && item.el.data('mfp-custom-tpl') != '') {
							item.src = mfpTpl($(item.src).clone(),item.el.data('mfp-custom-tpl'));
						}

						//Populate title
						if (typeof item.el.data('mfp-title') != 'undefined' && item.el.data('mfp-title') != '') {
							$(item.src).find('#popupTitleArea > .title').html(item.el.data('mfp-title'));
						}

						//Populate content
						if (typeof item.el.data('mfp-content') != 'undefined' && item.el.data('mfp-content') != '') {
							$(item.src).find('#popupContent').html(item.el.data('mfp-content'));
						}
					}
				},
				change: function() {},
				resize: function() {},
				open: function() {
					if ($('#feedbackify').length) {
						$('#feedbackify').hide();
					}

					var item = this.currItem,
						container = this.container;

					//Add optional main class
					if (typeof item.el.data('mfp-main-class') != 'undefined' && item.el.data('mfp-main-class') != '') {
						container.closest('.mfp-wrap').addClass(item.el.data('mfp-main-class'));
					}
					//Populate height and width
					if (typeof item.el.data('mfp-height') != 'undefined' && item.el.data('mfp-height') != '') {
						container.find('.mfp-content').css({
							'height' : item.el.data('mfp-height'),
							'overflow' : 'auto'
						});
					}
					if (typeof item.el.data('mfp-width') != 'undefined' && item.el.data('mfp-width') != '') {
						container.find('.mfp-content').css('max-width', item.el.data('mfp-width'));
						container.find('.mfp-close-wrapper').css({
							'max-width':item.el.data('mfp-width'),
							'margin':'0 auto'
						});
					}

                    if (typeof item.el.data('mfp-modal') != 'undefined' && item.el.data('mfp-modal') == true) {
                        container.find('.mfp-close').addClass('hidden');
                    }

					lightboxOpenFocusFix(item, container);
				},
				beforeClose: function() {
                    if ($('.mfp-wrap').css('position') == "absolute") {
						$('html,body').scrollTop($('.mfp-wrap').offset().top);
					}
                },
				close: function() {
					$('#container').removeAttr('aria-hidden');
					if ($('#feedbackify').length) {
						$('#feedbackify').show();
					}
				},
				afterClose: function() {},
				markupParse: function(template, values, item) {
					//Populate iframe height
					if (typeof item.el.data('mfp-iframe-height') != 'undefined' && item.el.data('mfp-iframe-height') != '') {
						$(template).find('iframe').css('height', item.el.data('mfp-iframe-height'));
					}
					//Populate iframe width
					if (typeof item.el.data('mfp-iframe-width') != 'undefined' && item.el.data('mfp-iframe-width') != '') {
						$(template).find('iframe').css('width', item.el.data('mfp-iframe-width'));
					}
				},
				updateStatus: function (data) {},
				parseAjax: function(mfpResponse) {
					var currItem = this.currItem.el;

					//Test if <main> in ajax response is has class tpl-v4
					var isTplv4 = (($(mfpResponse.data).find('.tpl-v4').length > 0) ? true:false);

					//Only load part of the ajax response
					if (typeof currItem.data('mfp-filter') != 'undefined' && currItem.data('mfp-filter') != '') {
						//mfpResponse.data = $(mfpResponse.data).find(currItem.data('mfp-filter')).html();
						//mfpResponse.data = $('<div/>').addClass('popupContainer').html($(mfpResponse.data).find(currItem.data('mfp-filter')).html());
						mfpResponse.data = $('<div/>').addClass('clearfix').html($(mfpResponse.data).find(currItem.data('mfp-filter')).html());
					}

					//Wrap results in default template
					if (typeof currItem.data('mfp-tpl') != 'undefined' && currItem.data('mfp-tpl') == true) {
						mfpResponse.data = mfpTpl(mfpResponse.data);
					}

					//Wrap results in custom template
					if (typeof currItem.data('mfp-custom-tpl') != 'undefined' && currItem.data('mfp-custom-tpl') != '') {
						mfpResponse.data = mfpTpl(mfpResponse.data,currItem.data('mfp-custom-tpl'));
					}

					//Populate title
					if (typeof currItem.data('mfp-title') != 'undefined' && currItem.data('mfp-title') != '') {
						$(mfpResponse.data).find('#popupTitleArea > .title').html(currItem.data('mfp-title'));
					}

					//Apply popupContainer class if the results don't include the container
					if (!$(mfpResponse.data).is('#popupContainer')) {
						mfpResponse.data = $(mfpResponse.data).addClass('popupContainer');
					}

					// Apply tpl-v4 class if the results had the class
					if (isTplv4 && $(mfpResponse.data).find('.tpl-v4').length === 0) {
						mfpResponse.data = $(mfpResponse.data).addClass('tpl-v4');
					}

					//Format itinerary images and table
					if ($(mfpResponse.data).find('#itin-content').length > 0) {
						findAndCompare.waitForElement('#itin-content', function(){
							findAndCompare.missingPortImg();
							findAndCompare.missingMapImg();
							if(mobile != 'true'){
								findAndCompare.adjustTextLength();
								findAndCompare.adjustHeight();
							}
						});
					}
				},
				ajaxContentAdded: function() {
					var currItem = this.currItem.el,
						container = this.container;

					//runP13N
					if ($(this.content).find('span[class^="p13nListItemId_"]').length) {
						runP13N('.popupContainer');
					}

					//Init droptabs-easytabs
					if ($(this.content).find('.drop-tab').length) {
						droptabs.init($(this.content).find('.drop-tab'), '.popupContainer');
					}

					if ($(this.content).find('.tab-nav-menu').length) {
						$(this.content).find('.tab-nav-menu').tabNav({
							$container: $(this.content),
							debug: true
						});
					}

					//Set width of close button wrapper if using data-mfp-width on an ajax lightbox
					if (typeof currItem.data('mfp-width') != 'undefined' && currItem.data('mfp-width') != '') {
						container.find('.mfp-close-wrapper').css({
							'max-width':currItem.data('mfp-width'),
							'margin':'0 auto'
						});
					}
					//Initialize addthis if the it is included in the ajax results
					if ($(this.content).find('#fbPrLikeWidget').length > 0 || $(this.content).find('.action-share-drawer').length > 0) {
						//check to see if addthis has been loaded in current window
						if (window.addthis) {
							addthis.toolbox('.addthis_toolbox');
							addthis.counter('.addthis_counter');
						} else {
							$.getScript('//s7.addthis.com/js/250/addthis_widget.js#pubid=ra-4fc673615553b316#async=1');
						}
					}

					//Initialize panorama player
					if (currItem.hasClass('panorama')) {
						var $panorama = $(this.content).find('#panorama-view');
						if(!$.fn.panorama360){
							$.when(
								$.ajax({
									url: '/css/global/lightbox-panorama.css',
									cache: 'true',
									dataType: 'text'
								}),
								$.ajax({
									url: '/js/global/jquery/plugins/panorama/jquery.panorama.js',
									cache: 'true',
									dataType: 'script'
								})
							).then(function(css,js) {
								$("head").append("<style>" + css + "</style>");
								$panorama.panorama360();
							});
						}else{
							$panorama.panorama360();
						}
					}

					//Initialize action widget if it is included in the ajax results
					if ($(this.content).find('.actions-widget-list').length > 0) {
						if (typeof currItem.data('mfp-hide-action') != 'undefined' && currItem.data('mfp-hide-action') == true) {
							//hide the widget when it's not needed
							$(this.content).find('.actions-widget-list').addClass('hidden');
						} else {
							//initialize action widget share
							if (typeof initShareAction == 'function') {
								initShareAction($(this.content));
							}

							//initialize action widget special offers button if present in lightbox content and in nav of parent page
							if ($(this.content).find('.action-offers').length > 0) {
								if (typeof initSpecialOfferAction == 'function') {
									initSpecialOfferAction($(this.content));
								}
							}
							//initialize action widget brochure button if present  in lightbox content and in nav of parent page
							if ($(this.content).find('.action-brochure').length > 0) {
								if (typeof initBrochureAction == 'function') {
									initBrochureAction($(this.content));
								}
							}
						}
					}

					//when an anchor tag is present in the href that opens the mfp lighbox, scroll content into view
					if (currItem.prop('href') && currItem.prop('href').indexOf('#') !== -1) {
						var target = currItem.prop('href').split('#')[1],
							$mfpContent = $(this.content),
							mfpAnchorScrollCount = 0,
							mfpAnchorScrollCheck = setInterval(function() {

							if ($mfpContent.find('#' + target).length > 0) {
								//stop checking for the anchor scroll
								clearInterval(mfpAnchorScrollCheck);

								var anchorOffsetTop = $mfpContent.find('#' + target).offset().top,
									mfpwrapOffsetTop = $('.mfp-wrap').offset().top,
                                    mfpwrapMobile = $('.mfp-wrap').css('position') == "absolute";
								//desktop : .mfp-wrap is fixed
                                //mobile responsive : .mfp-wrap is absolute
                                if (mfpwrapMobile) {
                                    $('html,body').stop().animate({scrollTop: anchorOffsetTop - 20 }, 500);
                                }
                                else {
                                    $('.mfp-wrap').stop().animate({scrollTop: anchorOffsetTop - mfpwrapOffsetTop - 20 }, 500);
                                }

							}

							//stop checking for the anchor scroll after 5 seconds
							if (mfpAnchorScrollCount < 201) {
								mfpAnchorScrollCount++;
							} else {
								clearInterval(mfpAnchorScrollCheck);
							}
						},50);
					}
				}
			}
		});
	}

	function mfpTpl(content,tplId) {
		//default template
		var $tpl = $('<div />',{'id':'popupContainer', 'class':'container'}).html('<div id="popupTitleArea"> <span class="ir seawitch"></span> <h2 class="title"></h2> </div> <div id="popupContent" class="col-xs-12"></div>');

		//grab custom template is provided
		if (typeof tplId != 'undefined') {
			$tpl = $(tplId).html();
		}

		var $markup = $tpl;

		//convert string into jquery object
		if (typeof $markup == 'string') {
			var parseHTML = $.parseHTML($markup);
			$markup = $(parseHTML[1]);
		}

		//insert content into template
		if ($(content).hasClass('hidden')) {
			content = $(content).removeClass('hidden');
		}
		if ($(content).hasClass('mfp-hide')) {
			content = $(content).removeClass('mfp-hide');
		}

		$markup.find('#popupContent').html(content);

		return $markup;
	}

}(jQuery));

function lightboxOpenFocusFix(mfpItem, mfpContainer, mfpTitle) {
	$('#container').attr('aria-hidden', 'true');

	var	modalHeading = mfpContainer.find('h1').text(),
		itemData = mfpItem.el ? mfpItem.el.data('mfp-title') : '',
		itemText = mfpItem.el ? mfpItem.el.text() : '',
		modalLabel = modalHeading ? modalHeading : itemData || itemText;

	//Populate Title and Role Attribute
	if (mfpItem.type === 'iframe') {
		if(mfpTitle.includes("Special Offers Just for you")){
			var iframeTitle = mfpTitle ? mfpTitle : itemData ? itemData : itemText;
			mfpContainer.closest('.mfp-wrap').attr({'aria-label': mfpTitle, 'role': 'dialog'});
			mfpContainer.find('iframe').attr('title', iframeTitle);
		}
		else{
		var iframeTitle = mfpTitle ? mfpTitle : itemData ? itemData : itemText;
		mfpContainer.closest('.mfp-wrap').attr({'aria-label': modalLabel, 'role': 'dialog'});
		mfpContainer.find('iframe').attr('title', iframeTitle);
		}
	} else {
		//For non-iframe modals, add aria-label and role attributes
		mfpContainer.closest('.mfp-wrap').attr({'aria-label': modalLabel, 'role': 'dialog'});
	}

	mfpContainer.find('h1,h2,h3,a,button,iframe,input,select,span[role="button"],div[role="button"]').not('button.mfp-close').first().trigger('focus');
}

// Web components Lightbox modal listeners
window.addEventListener('web-component.onClick', function  (data) {
	$.magnificPopup.open({
		items: {
			src: data.detail.src,
			type: data.detail.type,
		},
		removalDelay: 300,
		alignTop: true,
		modal: false,
		closeMarkup:
		'<div class="mfp-close-wrapper"><div class="mfp-close-inner-wrapper"><button class="mfp-close icon-no-text" data-test-id="mfp-close" aria-label="Close"><svg><use href="#icon-close-x"></use></svg></button></div></div>',
		mainClass: `border-box-sizing ${data.detail.mainClass}`,
		overflowY: 'scroll',
		callbacks: {
			beforeOpen: function () {
				$(document).on('click', '.open-mfp', function (e) {
					if (e.target.dataset.mfpModal) {
						$.magnificPopup.instance.st.modal = true;
						$.magnificPopup.instance.st.closeOnContentClick = false;
						$.magnificPopup.instance.st.closeOnBgClick = false;
						$.magnificPopup.instance.st.showCloseBtn = false;
						$.magnificPopup.instance.st.enableEscapeKey = false;
					}
				});
			},
			open: function () {
				var item = this.currItem,
					container = this.container;
					const title='Special Offers Just for you';
					if( $.magnificPopup.instance.st.mainClass?.includes('special-offers')){
						window.lightboxOpenFocusFix(item, container,title);
					}
				window.lightboxOpenFocusFix(item, container);
			},
			beforeClose: function () {
				if ($('.mfp-wrap').css('position') == 'absolute') {
					$('html,body').scrollTop($('.mfp-wrap').offset().top);
				}
			},
			close: function () {
				$('#container').removeAttr('aria-hidden');
			},
			afterClose: function() {
				window.dispatchEvent(new CustomEvent('web-component.setFocus', {
					detail: {
						elementSelector: '.global-mfp-modal'
					}
				}));
			}
		}
	});
});

// Log Out Modal from Web Components
window.addEventListener('guest-auth.notice', function (data) {
    console.log('guestAuth: notice data', data);
	$.magnificPopup.open({
		items: {
			src: data.detail.src,
			type: 'inline'
		},
		removalDelay: 300,
		alignTop: false,
		modal: true,
		mainClass: 'border-box-sizing mfp-guestauth-notice',
		overflowY: 'scroll',
		callbacks: {
			open: function () {
				var item = this.currItem,
					container = this.container;

				container.on('click', '#guestauth-notice-primary-cta', function () {
					var event = $(this).data('guestauthNotice');
					switch (event) {
						case 'inactive':
							container.find('.guestauth-notice-cta').addClass('hidden');
							container.find('.guestauth-notice-processing').removeClass('hidden');
							!!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - primary cta refreshToken');
							!!window.guestAuth && window.guestAuth.tokenRefresh();
							break;
					}
				});

				container.on('click', '#guestauth-notice-secondary-cta', function () {
					var event = $(this).data('guestauthNotice');
					var appType = $(this).data('guestauthAppType');
					switch (event) {
						case 'inactive':
							container.find('.guestauth-notice-cta').addClass('hidden');
							container.find('.guestauth-notice-processing').removeClass('hidden');
							!!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - secondary cta logout');
							!!window.guestAuth && window.guestAuth.logout();
							break;
						case 'logoutComplete':
							!!window.guestAuth && window.guestAuth.debug() && console.log('guestAuth notice - secondary cta logoutComplete / refresh window');
							if (appType === 'spa') {
								$.magnificPopup.close();
							} else {
								window.location.reload();
							}
							break;
					}
				});
			}
		}
	});
});


