/*
 * Initialize the picture polyfill
 */
// global var is used to determine if the call to load the plugin has been made
var isPicturefillLoaded = false;
var pictureEl = {
	init: function ($container) {
		console.log('pictureEl.init');
		if ($container.find('picture').length > 0) {
			var broswerPass = this.browserTest();
			if (broswerPass) {
				this.usePolyfill();
			} else {
				this.useFallback($container);
			}
		}
	},
	browserTest: function () {
		if ($('html').hasClass('lt-ie9')) {
			return false;
		} else {
			return true;
		}
	},
	usePolyfill: function () {
		// load the plugin when needed
		if (isPicturefillLoaded === false) {
			// set the global var to true to prevent additional attempts to load the plugin
			isPicturefillLoaded = true;
			loadJS('/js/global/picturefill/picturefill.min.js',function () {
				picturefill();
			});
		} else {
			// test to make sure plugin is loaded before calling function, need to make sure network latency does not cause issues
			if (typeof picturefill == 'function') {
				picturefill();
			}
		}
	},
	useFallback: function ($container) {
		$container.find('picture').each(function () {
			var $parentContainer = $(this).parent(),
				$imgWrapper = $parentContainer.find('.lt-ie9_slide-img'),
				imgAttributes = $imgWrapper.data();
			$parentContainer.find('picture,source,img').remove();
			$imgWrapper.append('<img src="' + imgAttributes.src + '" alt="' + imgAttributes.alt + '" />');
		});
	}
};