/*
 * Country Drop Down
 */

/**
 * [countryDropdown - move popular countries to the top of the dropdown; auto-select country if enabled]
 * @type {Object}
 */
var countryDropdown = {
    /**
     * [init - declare vars and set up localeData usage]
     * @param  {Object} $country [country select element]
     */
    init: function($country) {
        var $option = $country.find('option'),
            $firstOption = $option.first(),
            selectCountries = ['GB', 'NZ', 'AU', 'CA', 'US'];

        // check to see if option el exists
        if ($option.length > 0) {
            // re-order options
            countryDropdown.reorder($country, $firstOption, selectCountries);
            // check to see if auto select data attribute exists and/or is true
            if (!!$country.data('country-select')) {
                // auto select country
                countryDropdown.initLocaleData($country);
            }
        }
    },
    /**
     * [reorder - move popular countries to the top]
     * @param  {Object}  $country        [country select element]
     * @param  {Object}  $firstOption    [option element labeled 'Select']
     * @param  {[Array]} selectCountries [popular countries]
     */
    reorder: function($country, $firstOption, selectCountries) {
        // iterate through popular countries
        selectCountries.forEach(function(country) {
            var $option = $country.find('option[value="' + country + '"]').clone();
            // append option elements
            $firstOption.after($option);
        });
    },
    /**
     * [initLocaleData - initialize localeData usage]
     * @param  {Object} $country [country select element]
     */
    initLocaleData: function($country) {
        // check to see if localeData has been initialized for use
        if (!localeData) {
            $(document).on('getLocale', function () {
                countryDropdown.autoSelect($country, localeData.country);
            });
        }
        else {
            countryDropdown.autoSelect($country, localeData.country);
        }
    },
    /**
     * [autoSelect - auto select current country is data-attribute is present]
     * @param  {Object} $country    [country select element]
     * @param  String   countryCode [country code]
     */
    autoSelect: function($country, countryCode) {
        return $country.find('option[value="' + countryCode + '"]').first().prop('selected', true).trigger('change');
    }
}
