/**
     * [surveyIntercept] - allow teams to easily load surveys invites on the site
     * @type {Object}
     */
window.surveyIntercept = {
    /**
     * default configuration 
     */
    'config': {
        'url': '#', // {string} survey url
        'title': 'We Value Your Opinion', // {string} headline in the survey intercept
        'desc': 'Please tell us about today&rsquo;s website visit, so we can give you the best possible online experience.', // {string} description text in the survey intercept
        'cta': 'Take Our Survey', // {string} call to action to open survey
        'duration': 15000, // {number} time in milliseconds for the survey intercept to be displayed
        'delay': 3000, // {number} time in milliseconds to wait for the survey to be displayed
        'targetSelector': 'main', // {string} selector for the main section of the site
        'forceDisplay': false, // {boolean} a means to force the intercept even when the user has seen the survey in the past
        'debug': false // {boolean} toggle console logs
    },

    /**
     * [timestamp] - new date object based on current time
     * @type {Date}
     */
    'timestamp': new Date(),
        
    /**
     * [getList] - retrieves list of surveys previously displayed for user
     * @return {array} - an array of survey urls
     */
    getList: function () {
        //Retrieve the object form storage
        let surveyCookieName = docCookies.nameItem('pcl_surveyList'), surveyList;

        // if cookie exists, store data in localeStorage, and delete cookie.
        if (docCookies.hasItem(surveyCookieName)) {
            surveyList = docCookies.getItem(surveyCookieName);
            localStorage.setItem('pcl_surveyList', surveyList);
            docCookies.removeItem(surveyCookieName, '/', '.princess.com');
        } else {
            surveyList = localStorage.getItem('pcl_surveyList');
        }

        if (surveyList === null || surveyList === 'undefined') {
            return [];
        } else {
            return JSON.parse(surveyList);
        }
    },

    /**
     * [updateList] - adds the current survey url to the list of surveys, and then saves the array in a cookie
     * @param  {arrya} surveyList - an array of survey urls
     * @param  {string} surveyUrl - current survey string
     */
    updateList: function (surveyList, surveyUrl) {
        // checks if current survey in the surveyList. This check is in place to prevent multiple entries of the same url when forceDisplay is on
        let addSurveyUrl = surveyList.some(function (url) {
            return url === surveyUrl;
        });

        if (!addSurveyUrl) {
            surveyList.push(surveyUrl);
        }
        
        if (surveyIntercept.config.debug === true) {
            console.log('surveyIntercept updateList:', surveyList);
        }
        
        localStorage.setItem('pcl_surveyList', JSON.stringify(surveyList));
    },

    /**
     * [init] - get's the party started
     * @param  {object} config - settings for survey interceptor 
     * @return {boolean} - to indicate if a survey will be displayed to the user
     */
    init: function (config) {
        // overwrites default setting with current settings
        if (config && typeof(config) === 'object') {
            Object.assign(surveyIntercept.config, config)
        } 

        // check surveryList array to see if one of the urls matches the existing survey url
        let surveyList = surveyIntercept.getList();
        let displaySurvey = surveyList.some(function (url) {
            return url === surveyIntercept.config.url;
        });

        if (surveyIntercept.config.debug) {
            console.log('surveyIntercept config:', surveyIntercept.config);
            console.log('surveyIntercept displaySurvey:', !displaySurvey);
        }

        // only display the survey interceptor if they have not seen this version before, or if forceDisplay is turned on
        if (!displaySurvey || surveyIntercept.config.forceDisplay) {
            surveyIntercept.timer(surveyIntercept.config, surveyList);
        }
    },

    /**
     * [display] will insert the interceptor template into the DOM for mobile or desktop design
     * @param  {Obj} tpl - the required markup for the survey interceptor
     * @return {Obj} - the survey interceptor as an object
     */
    display: function (tpl, targetSelector, debug) {
        let targetEl = document.querySelector(targetSelector),
        // check whether the survey is present in the DOM       
        existingSurvey = document.querySelector('.survey-container'),
        surveyContainer = null;
        surveyContainer === existingSurvey ? surveyContainer : surveyContainer = existingSurvey;

        if (debug) {      
            console.log('surveyIntercept tpl:', tpl);            
            console.log('surveyIntercept targetEl:', targetEl);
        }
        
        if (!existingSurvey) {  
            // test to find the current breakpoint to determine if mobile or desktop should be displayed      
            if (typeof windowBreakPoint === 'function') {
                let breakPoint = windowBreakPoint();
                if (debug) {
                    console.log('surveyIntercept break point:', breakPoint);
                }
                if (['xs', 'sm'].includes(breakPoint)){                
                    surveyContainer = targetEl.parentNode.insertBefore(tpl, targetEl);
                    surveyContainer.classList.add('mobile');
                } else {                         
                    surveyContainer = targetEl.parentNode.insertBefore(tpl, targetEl.nextElementSibling);
                    surveyContainer.classList.add('desktop');                                     
                }
            } else { 
                surveyContainer = targetEl.parentNode.insertBefore(tpl, targetEl.nextElementSibling);
                surveyContainer.classList.add('desktop');        
            }
        }  
        setTimeout(function () {
            surveyContainer.classList.remove('not-visible');
        }, 100);

        return surveyContainer;
    },

    /**
     * [hide] - hides the survey intercept
     * @param  {Obj} surveyContainer - the selector of the survey interceptor
     */
    hide: function (surveyContainer, debug) {
        if (debug) {
            console.log('surveyIntercept: hide');
        }
        surveyContainer.classList.add('not-visible');
    },

    /**
     * [timer] - sets the timers for the delay before the survey intercept is displayed, and when it's auto hidden
     * @param  {object} surveyConfigObj - the entire config object to populate the content into the template
     * @param  {array} surveyList - list of past surveys
     */
    timer: function (surveyConfigObj, surveyList) {
        let tpl = surveyIntercept.template(surveyConfigObj.title, surveyConfigObj.desc, surveyConfigObj.url, surveyConfigObj.cta);
        let displayTimer;

        // clear previously set timer
        if(!!surveyIntercept.delayTimer){
            clearTimeout(surveyIntercept.delayTimer);
        }

        // start timer to display the survey interceptor
        surveyIntercept.delayTimer = setTimeout(function () {
            if (surveyConfigObj.debug === true) {
                console.log('surveyIntercept display survey');
            }

            // display the survey intercept
            let surveyContainer = surveyIntercept.display(tpl, surveyConfigObj.targetSelector, surveyConfigObj.debug);

            // push the survey url into the stored cookie
            surveyIntercept.updateList(surveyList, surveyConfigObj.url);

            // start timer to hide survey interceptor
            displayTimer = setTimeout(function () {
                if (surveyConfigObj.debug === true) {
                    console.log('surveyIntercept hide survey');
                }

                // hide the survey interceptor
                if (surveyConfigObj.forceDisplay === false) {
                    surveyIntercept.hide(surveyContainer, surveyConfigObj.debug);
                }
            }, surveyConfigObj.duration);

            // hide the survey interceptor when the close or cta buttons are clicked
            [document.querySelector('.survey-cta-link'), document.querySelector('.survey-close-btn')].forEach(item => {
                item.addEventListener('click', event => {
                    surveyIntercept.hide(surveyContainer);
                    clearTimeout(displayTimer);
                })
                })
        }, surveyConfigObj.delay);
    },

    /**    
     * @param  {string} title - text to be displayed in headline
     * @param  {string} desc  - text to be displayed in the description
     * @param  {string} url   - link to survey
     * @param  {string} cta   - text to be displayed button
     * @return {string} - full html string to be inserted into the dom
     */
    template: function (title, desc, url, cta) {
        let tpl = document.createElement('div');
        tpl.classList.add('survey-container', 'not-visible');
        tpl.innerHTML = `<div class="survey-wrapper">
                        <h3 class="font-size-p4 gotham-xn-book survey-headline">${title}</h3>
                        <p class="survey-desc">${desc}</p>
                        <div class="survey-cta"><a href="${url}" target="_blank" class="button green-btn font-size-p6 survey-cta-link">${cta}</a></div>
                        <button type="button" class="survey-close-btn" aria-label="Close Survey Invite">
                        <svg class="svg-icon svg-icon-open-close"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-open-close"></use></svg>
                        </button></div>`;      
        return tpl;
    }
};

/**
* [loadSurveyIntercept] - this will load the required CSS / JS files and initialize survey interceptor
* @type {Object}
*
var loadSurveyIntercept = {
  'cssLoaded': false,
  'jsLoaded': false,
  // load the survey interceptor files on demand
  init: function (config) {
      loadSurveyIntercept.check(config);
      if (typeof surveyIntercept === 'object') {
          loadSurveyIntercept.cssLoaded = true;
          loadSurveyIntercept.jsLoaded = true;
      } else {
          var loadCSSFile = loadCSS('/css/global/intercept-survey.css');
          onloadCSS( loadCSSFile, function () {
              loadSurveyIntercept.cssLoaded = true;
          });

          loadJS('/js/global/intercept-survey.js', function () {
              loadSurveyIntercept.jsLoaded = true;
          });
      }
  },
  // wait for the css and js file to load before initialize surveyIntercept 
  check: function (config) {
      var check = setInterval(function () {
      if (loadSurveyIntercept.cssLoaded === true && loadSurveyIntercept.jsLoaded === true) {
          surveyIntercept.init(config);
          clearInterval(check);
          }
      }, 50);
  }
};

// ex. 1 with url populated. URL will always need to be defined
loadSurveyIntercept.init ({
  'url':'https://princess.qualtrics.com/jfe/form/SV_1CiydYvIM60cMBf'
});

// ex 2 with all possible options defined
loadSurveyIntercept.init ({
  'title':'We Value Your Opinion',
  'desc':'Please tell us about today&rsquo;s website visit, so we can give you the best possible online experience.',
  'cta':'Take Our Survey',
  'url':'#',
  'duration': 15000,
  'delay': 3000,
  'targetSelector': 'main', 
  'forceDisplay':true,
  'debug':true
});

*/