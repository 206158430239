/**
 * [equalHeightObjs] - Evaluate the height of a group of elements to set a min-height based on the tallest element
 *
 * @param {jQuery Object} $elements to adjust min-height
 * @param {Boolean} If setMinHeight is true, function will scan all $element(s) to find maxHeight. 
 *                  When false, min-height for all elements will be set to 0
 */
var equalHeightObj = {
	init: function ($elements,setMinHeight) {
		$elements.css('min-height', 0);
		if (setMinHeight) {
			var maxHeight = equalHeightObj.findMaxHeight($elements);
			maxHeight = Math.round(maxHeight) + 1;
			$elements.css('min-height', maxHeight);
		}
	},
	findMaxHeight: function ($elements) {
		var maxHeight = 0;
		$elements.each(function () {
			var currentHeight = $(this).outerHeight();
			if (currentHeight > maxHeight) {
				maxHeight = currentHeight;
			}
		});
		//return the maxHeight
		return maxHeight;
	}
};