function vertAlignCol(vertAlignRow) {
	var widthCat = windowBreakPoint();

	var alignColumns = function($row) {
		// check if vert align has previous maxHeight
		var rowHeight = $row.data('rowHeight'),
			maxHeight = 0;

		// find the max col height
		$row.children('div').each(function () {
			if ($(this).outerHeight() > maxHeight) {
				maxHeight = $(this).outerHeight();
			}
		});

		// if maxHeight and rowHeight does not match, go through the process of aligning the columns
		if (rowHeight != maxHeight) {
			$row.data('rowHeight',maxHeight);
			//apply margin-top to vertical-align column
			$row.children('div').each(function () {
				var $col = $(this),
					t = 0;
				// calculate the size for the top margin
				if ($col.outerHeight() < maxHeight && !$col.hasClass('col-' + widthCat + '-12') && !$col.hasClass('col-' + widthCat + '-no-vert-align')) {
					t = (maxHeight - $col.outerHeight()) / 2;
				}
				$col.css('margin-top',t);
			});
		}
	};

	switch (typeof vertAlignRow) {
		case 'string':
			$(vertAlignRow).each(function () {
				alignColumns($(this));
			});
		break;
		case 'object':
			alignColumns(vertAlignRow);
		break;
	}
}
