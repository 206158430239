const localStorageSpace = {
    /**
     * [getTotal]
     * returns current total local storage size
     * @param {bool} shouldLog 
     */
    getTotal: function(shouldLog) {
        let data = '';
        const sorted = this.sort();

        for (let key in sorted) {
            if (sorted.hasOwnProperty(key)) {
                data += sorted[key];
                const size = ((sorted[key].length * 16) / (8 * 1024)).toFixed(2);
                shouldLog && console.log(`${key}: ${size} KB`);
            }
        }

        const totalSize = ((data.length * 16) / (8 * 1024)).toFixed(2);
        const remainingSize = 5120 - totalSize;

        if (shouldLog) {
            console.log('');
            console.log(data ? `Total: ${totalSize} KB` : 'Empty (0 KB)');
            console.log(data ? `Remaining: ${remainingSize} KB` : '5 MB');
            return;
        }

        return parseInt(totalSize);
    },
    /**
     * [isUnderLimit]
     * returns boolean value of total size being under the limit; default is `4000` KB
     * @param {int} limit 
     */
    isUnderLimit: function(limit = 4000) {
        return this.getTotal() < limit;
    },
    /**
     * [log]
     * console logs indivual and total sizes
     */
    log: function() {
        this.getTotal(true);
    },
    /**
     * [sort]
     * sorts and returns keys alphabetically
     */
    sort: function() {
        return Object.keys(window.localStorage)
            .sort()
            .reduce((acc, key) => {
                acc[key] = window.localStorage[key];
                return acc;
            }, {});
    },
    /**
     * [clear]
     * clears expired 'pcl-' prefixed localStorage values
     */
    clear: function() {
        const storage = this.sort();
        for (let key in storage) {
            if (key.indexOf('pcl-') > -1) {
                const value = JSON.parse(localStorage.getItem(key));
                // check to see if we have `expiry` or `timestamp` & `ttl` combo
                if (value.expiry || (value.timestamp && value.ttl)) {
                    const now = Math.round((new Date()).getTime() / 1000);
                    const expiry = value.expiry || value.timestamp + value.ttl;
                    now > expiry && localStorage.removeItem(key);
                }
            }
        }
    }
}
// over 2MB, run `clear` method
!localStorageSpace.isUnderLimit(2000) && localStorageSpace.clear();