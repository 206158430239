/*
 * Scroll the browser when a user clicks on an anchor link.
 */
$(document).ready(function () {
	var anchorTag = location.hash;
	//check if the anchor tag element exists on the page
	if (anchorTag.length > 0 && isValidAnchor(anchorTag) && $(anchorTag).length) {
		showProduct( $(anchorTag) );
	}
	$(document).on('click', '.anchor-scroll, .anchor-btn', function (e) {
		e.preventDefault();
		//call function to scroll browser window to the #id
		var anchorTag = $( DOMPurify.sanitize($(this).attr('href')) );
		anchorScrollTo( $(anchorTag) );
		showProduct( $(anchorTag) );
	});

	$(document).on('change', '.anchor-scroll', function (e) {
		e.preventDefault();
		//capture the target
		var target = DOMPurify.sanitize($(this).val());
		//if the target begins with #, scroll to the anchor point. Otherwise, direct the browser to a new page
		if (target.indexOf('#') == 0) {
			//call function to scroll browser window to the #id
			anchorScrollTo( $(target) );
			showProduct( $(target) );
		} else {
			window.location = target; // redirect
		}
		//on change, remove focus from the select element, and then change the selected option to default
		$(this).trigger('blur').find('option:eq(0)').prop('selected',true);
	});

	//scroll to top of the page
	$(document).on('click','.scroll-top', function (e){
		e.preventDefault();
		$('html, body').stop().animate({ scrollTop: 0 }, 500);
	});
});

/*
 * [anchorScrollTo] - Instead of jumping to the element, this will scroll the browser to the element
 *
 * @param {Object} $anchor object - element being scrolled too
 */
function anchorScrollTo($anchor) {
	if ($anchor.length > 0) {
		var anchorOffsetTop = $anchor.offset().top;
		// test if we're srolling a lightbox vs a full web page
		if ($anchor.closest('.mfp-wrap').length > 0) {
			var mfpwrapOffsetTop = $('.mfp-wrap').offset().top;
			// first we try to scroll the lightbox only, this works for desktop
			$('.mfp-wrap').stop().animate({scrollTop: anchorOffsetTop - mfpwrapOffsetTop - 20 }, 500, function () {
				// on completion of scrolling the lightbox, we verify the anchor is in view
				// for mobile and tablet devices, the anchor will not be in view. When this happens, we'll scroll the page
				if ($anchor.offset().top - mfpwrapOffsetTop > $(window).height()) {
					$('html,body').stop().animate({scrollTop: anchorOffsetTop - 20 }, 500);
				}
				// focus on the first visible interactive element in #content
				$anchor.find('h2').attr('tabindex','-1').focus();
			});
		} else {
			$('html,body').stop().animate({scrollTop: anchorOffsetTop - 70 }, 500, function () {
				// focus on the first visible interactive element in #content
				$anchor.find('h2').attr('tabindex','-1').focus();
			});
		}
	}
}
/*
 * [showProduct] -if the anchor tag is part of the product pages, expand the card 
 *
 * @param {Object} $module object - parent element being scrolled too
 */
function showProduct($module) {
	if( isItaProduct($module) ){
	 var $alink = $module.find('a').first(); //adding timeout to give some time for the page to load
  
	setTimeout(function () {
	  $alink.trigger("click");
	  }, 0); 
	}
  }
 /*
 * [isValidAnchor]  - checking if the anchor tag does not have bad characters that would break dom query for the id
 * @param {String} hash - anchor tag from the url
 */
function isValidAnchor(hash) {
	var invalidAnchorCharacters = ['?', '=', ','];
	return invalidAnchorCharacters.every(function (invalidCharacter) {
		return hash.indexOf(invalidCharacter) === -1;
	});
}
  
  /*
 * [isItaProduct] -checking if the link is a product type, returns true for Cards or Wide module 
 *
 * @param {Object} $module object - parent element being scrolled too
 */
  function isItaProduct($module){
	return $module.hasClass("cards-nav-wrapper") || $module.hasClass("tab-nav-link");
  
  }