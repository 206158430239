class PclSystemOutage extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const template = document.createElement('template');
        template.innerHTML = `<div id="pcl-system-outage"></div>`
        this.attachShadow({ mode: "open" }).appendChild(
            template.content.cloneNode(true)
        );

        this.init();
    }

    init() {
        if (this.shadowRoot.querySelector('#pcl-system-outage')) {
            this.render();
        }
    }

    render() {
        const serialized = this.cookie().serialized,
            parsed = this.cookie().parsed,
            cookie = this.cookie().cookie,
            url = `${$wwwURL}/util/getOutage.do?brandName=PC`;

        // check to see if outage cookie exists
        if (!serialized || !cookie) {
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    this.processResponse(data);
                })
        } else {
            // check to see if outage exists
            if (parsed.data) {
                if (!sessionStorage.getItem('alert-closed')) {
                    this.checkOutagePeriod(parsed);
                }
            }
        }
    }

    cookie() {
        const outageCookie = docCookies.getItem(docCookies.nameItem("pcl_systemOutage"));
        const outageLocalStorage = localStorage.getItem("pcl_systemOutage");

        return {
            cookie: outageCookie,
            serialized: outageLocalStorage,
            parsed: JSON.parse(outageLocalStorage)
        };
    }

    formatData(response) {
        let self = this,
            start = this.formatDateTime(response[0]).start,
            end = this.formatDateTime(response[0]).end,
            outageInfo = { start: {}, end: {} };

        response.forEach(function (outage) {
            // get the earliest start date and time
            if (self.formatDateTime(outage).start <= start) {
                // set new start val
                start = self.formatDateTime(outage).start;
                // assign to formatted date to obj; we'll use this for template display
                outageInfo.start.date = start.getMonth() + 1 + "/" + start.getDate();
                outageInfo.start.time = outage.outageStartTime;
                outageInfo.start.full = start;
                outageInfo.start.timezone = timezone.getPacificTimezone(start);
            }
            // get the latest end date and time
            if (self.formatDateTime(outage).end >= end) {
                // set new end val
                end = self.formatDateTime(outage).end;
                // assign to formatted date to obj; we'll use this for template display
                outageInfo.end.date = end.getMonth() + 1 + "/" + end.getDate();
                outageInfo.end.time = outage.outageEndTime;
                outageInfo.end.full = end;
                outageInfo.end.timezone = timezone.getPacificTimezone(end);
            }

            outageInfo.sitewide = !!response.message;
        });

        return outageInfo;
    }

    formatDateTime(obj) {
        const startDate = `${obj.outageStartDate} ${obj.outageStartTime.slice(0, 5)}:00 ${obj.outageStartTime.slice(5, 7)}`,
            endDate = `${obj.outageEndDate} ${obj.outageEndTime.slice(0, 5)}:00 ${obj.outageEndTime.slice(5, 7)}`;
        return {
            start: timezone.getConvertedDateTime(startDate),
            end: timezone.getConvertedDateTime(endDate)
        };
    }

    checkOutagePeriod(parsed) {
        let template = this.getOutageMessage(parsed),
            currentDate = new Date(),
            startDate = new Date(
                new Date(parsed.start.full).getTime() - 24 * 60 * 60 * 1000
            ),
            endDate = new Date(parsed.end.full),
            outage = currentDate >= startDate && currentDate < endDate; // do not show 24 hours before start date and after end date

        if (outage) {
            this.buildTemplate(template);
        }
    }

    getOutageMessage(info) {
        // msg 1: "Sections of our website will be unavailable due to scheduled maintenance between 00:00 PST (on 00/00) and 00:00 PST on 00/00."
        // msg 2: "Princess.com will be down for scheduled service between 00:00 PST (on 00/00) and 00:00 PST on 00/00, but we'll set sail soon!"
        return (info.sitewide
                ? "Princess.com will be down for scheduled service between "
                : "Sections of our website will be unavailable due to scheduled maintenance between ") +
            `${info.start.time} ${info.start.timezone}` +
            (info.start.date !== info.end.date ? " on " + info.start.date : " ") +
            ` and ${info.end.time} ${info.end.timezone}  on ${info.end.date}` +
            (info.sitewide ? ", but we'll set sail soon!" : "");
    }

    processResponse(response) {
        if(response.data) {
            const filtered = response.data;
            // check to see if PC outage exists
            if (filtered.length) {
                const formattedData = this.formatData(filtered),
                    debug = {
                        'data': filtered,
                        'status': response.status,
                        'numberOfOutages': filtered.length,
                        'start': formattedData.start,
                        'end': formattedData.end,
                        'sitewide': formattedData.sitewide
                    };

                // set outage cookie (15 mins)
                docCookies.setItem(docCookies.nameItem('pcl_systemOutage'), true, 900, '/', 'princess.com', true);
                localStorage.setItem('pcl_systemOutage', JSON.stringify(debug));
                // append template
                this.checkOutagePeriod(formattedData);
            } else {
                // set outage cookie (4 hour)
                docCookies.setItem(docCookies.nameItem('pcl_systemOutage'), false, 14400, '/', 'princess.com', true);
                localStorage.setItem('pcl_systemOutage', 'No outages available');
            }
        } else {
            docCookies.setItem(docCookies.nameItem('pcl_systemOutage'), false, 14400, '/', 'princess.com', true);
            localStorage.setItem('pcl_systemOutage', JSON.stringify(response.message));
        }
    }

    buildTemplate(message) {
        this.shadowRoot.querySelector('#pcl-system-outage').innerHTML = `
            <link rel="stylesheet" href="${this.getAttribute('baseurl') || $wwwURL}/css/global/web-components/pcl-system-outage/pcl-system-outage.css" />
            <div class="system-outage">
               <div class="system-outage__alert-bar">
                    <div class="container white">
                        <div class="system-outage__alert-bar--icon">
                            <svg id="icon-warning" xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22"><title>icon-warning</title><path d="M22.78,18.27,13.432,2.08a1.65,1.65,0,0,0-2.858,0L1.227,18.27a1.65,1.65,0,0,0,1.429,2.475h18.7A1.65,1.65,0,0,0,22.78,18.27ZM12.926,6.249l-.141,7.83H11.163l-.123-7.83ZM13.05,18.24h-2.1V16.3h2.1Z" fill-rule="evenodd"/></svg>
                        </div>
                        <div class="system-outage__alert-bar--text">
                           <span class="message white text-left gotham-xn-book">
                                ${message}
                            </span>
                        </div>
                        <div class="system-outage__alert-bar--close">
                            <button>
                                <svg id="icon-open-close" viewBox="0 0 24 24">
                                    <title>open / close icon</title><path fill-rule="evenodd" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm6.2,16.12a.75.75,0,1,1-1.06,1.06L12,13.06,6.88,18.19a.75.75,0,0,1-1.06-1.06L10.95,12,5.82,6.87A.75.75,0,0,1,6.88,5.81L12,10.94l5.13-5.13a.75.75,0,1,1,1.06,1.06L13.07,12Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        `;

        this.closeButton$ = this.shadowRoot.querySelector(".system-outage__alert-bar--close");

        this.closeButton$.addEventListener('click', () => {
            this.shadowRoot.querySelector('#pcl-system-outage').classList.add('hidden');
            sessionStorage.setItem('alert-closed', true);
            return true;
        });
    }
}

customElements.define("pcl-system-outage", PclSystemOutage);