jQuery(document).ready(function ($) {
	//if this is a modern browser, exit script
	var $html = $('html'),
		browserNotice = getCookie('browserNotice');
	
	if ($html.hasClass('lt-ie9') || $html.hasClass('lt-ie8') || $html.hasClass('ie7') || $html.hasClass('ie6')) {
		if (browserNotice == null || browserNotice == "") {
			displayBrowserNotice();
		}
	}

	function displayBrowserNotice() {
		var noticeContent = '',
			noticeTitle = 'Please Upgrade Your Web Browser',
			noticeDesc = 'We&rsquo;ve rebuilt our website using the latest technologies to make it perform better and be easier to use. Unfortunately, a more up-to-date browser is needed to support those technologies. <a href="http://windows.microsoft.com/ie" class="white">Update your browser now.</a>',
			ipUrl = '/ip79f.jsp';
			
		//check protocol to determine correct url for ip check
		if (pageProtocol == 'https:') {
			ipUrl = '/util/monitorIp.page';
		}
			
		$.ajax({
			url: ipUrl
		})
		.done(function (results) {
			var internalUser = false;
			//test for internal users
			if (results.indexOf(' 172.29.') != -1 || results.indexOf(' 10.2.') != -1) {
				internalUser = true;
			}
			//change messaging for internal users to turn off compatibility mode
			if (internalUser) {
				noticeTitle = 'Attention Internal Users';
				noticeDesc = 'Internet Explorer users: please use a newer version (IE 9 or later) and disable Compatibility Mode (under the tools menu) for princess.com to function properly, or use a more up-to-date browser like Firefox or Chrome if available.';
			}
		})
		.always(function () {
			noticeContent = '<div id="browser-notice" style="background: #4D4D4D; border-bottom: 1px solid #666; display: none; position: relative; z-index: 999;"><div class="inner-wrapper" style="color: #fff; margin: 0 auto; padding: 5px 10px 5px; width: 980px;"><p style="color:#fff; font-size: 13px; margin: 1em 0 .5em;"><a href="#" id="close-browser-notice" style="display: block; color: white; float: right; font-size: 13px; font-weight: 700; overflow: hidden; width: 75px;"><span style="direction: ltr;display: block; overflow: hidden; text-align: left; text-indent: -999em; float: left; height: 14px; width: 14px; background: url(/images/global/sprite_pc-icons.png) no-repeat -90px -42px transparent; margin-right: 5px;">[x]</span>Close</a><span class="highlight" style="background: #F26522; font-size: 15px; letter-spacing: .5px; padding: 4px 10px;">' + noticeTitle + '</span></p><p style="color:#fff; font-size: 13px; margin: 1em 0 .5em;">' + noticeDesc + '</p></div></div>';
		
			if ($('#important-update').length) {
				$('#important-update').before(noticeContent);
			} else {
				$('body').prepend(noticeContent);
			}
			
			$('#browser-notice').slideDown();
			
			$(document).on('click','#close-browser-notice',function (e) {
				e.preventDefault();
				setSessionCookie('browserNotice', 'hide', 240); //240 = 4 hours in minutes
				$('#browser-notice').slideUp('fast');
			});
		});
	}
	
});