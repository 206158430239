/**
 * [windowCat] - Returns window width categorized to match default responsive breakpoints
 *
 * @param {Number} width
 * @return {string} returns the category of the window width
 */
function windowCat(width) {
	if (width < 640) return 'xs';
	if (width >= 640 && width < 768) return 'sm';
	if (width >= 768 && width < 992) return 'md';
	if (width >= 992) return 'lg';
}

/**
 * [windowBreakPoint] - returns window breakpoint
 */
function windowBreakPoint() {
    var computedStyle = window.getComputedStyle(document.querySelector('.rwd'), ':before');
    if (computedStyle === null) {
        return 'lg';
    } else {
        return computedStyle.getPropertyValue('content').replace(/\"/g, '');
    }
}

/**
 * [windowResize] - trigger custom event 'windowResize' on window resize
 */
var hasResized = false,
    windowWidth = $(window).width();

$(window).on('resize', function (e) {
    // check to see if window width has changed; set as var
    hasResized = $(window).width() !== windowWidth;
});

setInterval(function () {
    if (hasResized) {
        // reset var
        hasResized = false;
        // update window width
        windowWidth = $(window).width();
        // trigger window resize event
        $(document).trigger('windowResize');
    }
}, 50);

/**
 * [windowScroll] - trigger custom event 'windowScroll' on window scroll
 */
var hasScrolled = false;
$(window).on('scroll', function (e) {
    hasScrolled = true;
});
setInterval(function () {
    if (hasScrolled) {
        hasScrolled = false;
        $(document).trigger('windowScroll');
    }
}, 50);
