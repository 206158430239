class PclCaptainCircleHeader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const template = document.createElement('template');
        template.innerHTML = `<div id="pcl-captain-circle-header"></div>`
        this.attachShadow({ mode: "open" }).appendChild(
            template.content.cloneNode(true)
        );

        this.checkAuthStatus();
        this.init();
    }

    checkAuthStatus() {
        if (window.digitalData && window.digitalData.user && window.digitalData.user.auth) {
            const authStatus = window.digitalData.user.auth.status;
            this.setAttribute('guest-auth', authStatus);
        }
    }

    init() {
        this.pclLang = 'en';
        if (this.shadowRoot.querySelector('#pcl-captain-circle-header')) {
            `${this.linkStylesheet(`${$bookURL}/css/global/web-components/pcl-captain-circle-header/pcl-captain-circle-header.css`)
                .then(
                    () => {
                        const linkElem = document.createElement("link");
                        linkElem.setAttribute("rel", "stylesheet");
                        linkElem.setAttribute("href", `${$bookURL}/css/global/web-components/pcl-captain-circle-header/pcl-captain-circle-header.css`);

                        // Attach the created element to the shadow DOM
                        this.shadowRoot.prepend(linkElem);
                    },
                    () => console.error("style could not be loaded"),
                ).then(() => {
                    this.linkStylesheet(`${$wwwURL}/css/global/princess-normalize.css`)
                        .then(
                            () => {
                                const normalize = document.createElement("link");
                                normalize.setAttribute("rel", "stylesheet");
                                normalize.setAttribute("href", `${$wwwURL}/css/global/princess-normalize.css`);
                                this.shadowRoot.prepend(normalize);
                            }).then(() => {
                                this.buildTemplate();
                                this.buildMobileTemplate();
                                this.buildManageBookingModalTemplate();
                                this.buildLoginModalTemplate();
                                this.setupEventListeners();
                            })
                })
                }`
        }
    }

    buildTemplate() {
        let isGuestAuthenticated = this.getAttribute('guest-auth') === 'true'; 
        this.shadowRoot.querySelector('#pcl-captain-circle-header').innerHTML = /*html*/`
            <header class="captain-circle-global-header desktop">
                    <div class="header-container">
                        <div class="header-actions-container">
                            <a class="header-action subscribeModal" href='${$bookURL}/captaincircle/specialOfferRegistration.page?track=header&amp;trackId=special-offers' data-track="header" data-track-id="special-offers">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.875 5.625V14.375C18.875 15.4105 18.0355 16.25 17 16.25H4.5C3.46447 16.25 2.625 15.4105 2.625 14.375V5.625M18.875 5.625C18.875 4.58947 18.0355 3.75 17 3.75H4.5C3.46447 3.75 2.625 4.58947 2.625 5.625M18.875 5.625V5.82726C18.875 6.47837 18.5372 7.08287 17.9827 7.42412L11.7327 11.2703C11.13 11.6411 10.37 11.6411 9.76732 11.2703L3.51732 7.42412C2.96279 7.08287 2.625 6.47837 2.625 5.82726V5.625" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                Subscribe
                                </a>
                            <a class="header-action" href='tel:18007746237' data-track="header" data-track-id="call">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.625 5.625C2.625 12.5286 8.22144 18.125 15.125 18.125H17C18.0355 18.125 18.875 17.2855 18.875 16.25V15.107C18.875 14.6768 18.5822 14.3018 18.1649 14.1975L14.4789 13.276C14.113 13.1845 13.7278 13.3212 13.5015 13.623L12.693 14.701C12.4583 15.0139 12.0525 15.1522 11.6853 15.0177C8.92949 14.0082 6.7418 11.8205 5.7323 9.06472C5.59778 8.69749 5.73613 8.29165 6.049 8.057L7.12702 7.24849C7.42878 7.02217 7.56551 6.63704 7.47403 6.27111L6.55253 2.58512C6.44819 2.16778 6.07321 1.875 5.64302 1.875H4.5C3.46447 1.875 2.625 2.71447 2.625 3.75V5.625Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                1-800-774-6237
                            </a>
                            <a class="header-action searchBtn" href='${$wwwURL}/search/' data-track="header" data-track-id="search">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.91667 9.16667C4.91667 6.40917 7.15917 4.16667 9.91667 4.16667C12.6742 4.16667 14.9167 6.40917 14.9167 9.16667C14.9167 11.9242 12.6742 14.1667 9.91667 14.1667C7.15917 14.1667 4.91667 11.9242 4.91667 9.16667ZM18.0058 16.0775L15.1767 13.2475C16.0542 12.1192 16.5833 10.705 16.5833 9.16667C16.5833 5.49083 13.5925 2.5 9.91667 2.5C6.24083 2.5 3.25 5.49083 3.25 9.16667C3.25 12.8425 6.24083 15.8333 9.91667 15.8333C11.455 15.8333 12.8692 15.3042 13.9975 14.4267L16.8275 17.2558C16.99 17.4183 17.2033 17.5 17.4167 17.5C17.63 17.5 17.8433 17.4183 18.0058 17.2558C18.3317 16.93 18.3317 16.4033 18.0058 16.0775Z" fill="#6C6C78"/>
                                </svg>
                                Search
                            </a>
                        </div>
                        <div id="header-login-buttons-container" class="header-actions-container">
                        ${isGuestAuthenticated ? 
                            `<a class="header-action" href="${$wwwURL}/cruise-vacation-planning-dashboard/home.do" data-track="header" data-track-id="saved">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Icon">
                                <path id="Union" d="M18.75 7.09091C18.75 4.83156 16.8845 3 14.5833 3C12.8628 3 11.3858 4.02389 10.75 5.48493C10.1142 4.02389 8.63722 3 6.91667 3C4.61548 3 2.75 4.83156 2.75 7.09091C2.75 13.6551 10.75 18 10.75 18C10.75 18 18.75 13.6551 18.75 7.09091Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </g>
                                </svg>
                                Saved
                            </a>` : ''}
                            <div id="manage-booking-button" class="manage-booking-button">
                                <button class="header-action" data-track-component-level-1="header" data-track="manage-dropdown" data-track-id="manage-booking">
                                    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8.75" cy="9" r="8" stroke="#6C6C78" stroke-width="1.5"></circle>
                                    <path d="M9.03447 10.3663L5.50054 12.3797L7.51271 8.84363L9.03447 10.3663Z" stroke="#6C6C78"></path>
                                    <path d="M8.59854 7.76595L12.1306 5.75265L10.1207 9.28809L8.59854 7.76595Z" stroke="#6C6C78"></path>
                                    </svg>
                                    Manage Booking
                                    <span class="header-chevron">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 12.9162C9.78655 12.9162 9.57322 12.8346 9.41072 12.6721L6.07738 9.33872C5.75155 9.01289 5.75155 8.48622 6.07738 8.16039C6.40322 7.83456 6.92988 7.83456 7.25572 8.16039L10.0099 10.9146L12.754 8.26456C13.0865 7.94539 13.6124 7.95456 13.9324 8.28539C14.2524 8.61622 14.2432 9.14456 13.9124 9.46372L10.579 12.6821C10.4165 12.8387 10.2082 12.9162 9.99988 12.9162Z" fill="#020215"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div id="login-button" class="login-button">
                                <button class="header-action" data-track-component-level-1="header" data-track="login-dropdown" data-track-id="login">
                                    <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0669 16.4776C14.8497 14.8711 12.921 13.8333 10.75 13.8333C8.57898 13.8333 6.65035 14.8711 5.43308 16.4776M16.0669 16.4776C17.7131 15.0123 18.75 12.8772 18.75 10.5C18.75 6.08172 15.1683 2.5 10.75 2.5C6.33172 2.5 2.75 6.08172 2.75 10.5C2.75 12.8772 3.78689 15.0123 5.43308 16.4776M16.0669 16.4776C14.6536 17.7357 12.791 18.5 10.75 18.5C8.70896 18.5 6.84645 17.7357 5.43308 16.4776M13.4167 8.5C13.4167 9.97276 12.2228 11.1667 10.75 11.1667C9.27724 11.1667 8.08333 9.97276 8.08333 8.5C8.08333 7.02724 9.27724 5.83333 10.75 5.83333C12.2228 5.83333 13.4167 7.02724 13.4167 8.5Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    ${isGuestAuthenticated ? `My Account` : `Login`}
                                    <span class="header-chevron">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 12.9162C9.78655 12.9162 9.57322 12.8346 9.41072 12.6721L6.07738 9.33872C5.75155 9.01289 5.75155 8.48622 6.07738 8.16039C6.40322 7.83456 6.92988 7.83456 7.25572 8.16039L10.0099 10.9146L12.754 8.26456C13.0865 7.94539 13.6124 7.95456 13.9324 8.28539C14.2524 8.61622 14.2432 9.14456 13.9124 9.46372L10.579 12.6821C10.4165 12.8387 10.2082 12.9162 9.99988 12.9162Z" fill="#020215"/>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
            <div class="global-header__nav-container-overlay hidden-md-up"></div>
        `;
    }

    buildMobileTemplate() {
        let isGuestAuthenticated = this.getAttribute('guest-auth') === 'true';
        const manageBookingButton = this.shadowRoot.querySelector('#pcl-captain-circle-header');
        const manageBookingMobileTemplate = document.createElement('template');

        manageBookingMobileTemplate.innerHTML = `
            <header class="captain-circle-global-header mobile">
                <div class="header-container">
                    <div class="header-actions-container">
                        <div class="header-actions-container-section">
                            <a class="header-action has-line searchBtn" href='${$wwwURL}/search/' data-track="header" data-track-id="search">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.91667 9.16667C4.91667 6.40917 7.15917 4.16667 9.91667 4.16667C12.6742 4.16667 14.9167 6.40917 14.9167 9.16667C14.9167 11.9242 12.6742 14.1667 9.91667 14.1667C7.15917 14.1667 4.91667 11.9242 4.91667 9.16667ZM18.0058 16.0775L15.1767 13.2475C16.0542 12.1192 16.5833 10.705 16.5833 9.16667C16.5833 5.49083 13.5925 2.5 9.91667 2.5C6.24083 2.5 3.25 5.49083 3.25 9.16667C3.25 12.8425 6.24083 15.8333 9.91667 15.8333C11.455 15.8333 12.8692 15.3042 13.9975 14.4267L16.8275 17.2558C16.99 17.4183 17.2033 17.5 17.4167 17.5C17.63 17.5 17.8433 17.4183 18.0058 17.2558C18.3317 16.93 18.3317 16.4033 18.0058 16.0775Z" fill="#6C6C78"/>
                                </svg>
                                Search
                            </a>
                            <a class="header-action" href='tel:18007746237' data-track="header" data-track-id="call">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.625 5.625C2.625 12.5286 8.22144 18.125 15.125 18.125H17C18.0355 18.125 18.875 17.2855 18.875 16.25V15.107C18.875 14.6768 18.5822 14.3018 18.1649 14.1975L14.4789 13.276C14.113 13.1845 13.7278 13.3212 13.5015 13.623L12.693 14.701C12.4583 15.0139 12.0525 15.1522 11.6853 15.0177C8.92949 14.0082 6.7418 11.8205 5.7323 9.06472C5.59778 8.69749 5.73613 8.29165 6.049 8.057L7.12702 7.24849C7.42878 7.02217 7.56551 6.63704 7.47403 6.27111L6.55253 2.58512C6.44819 2.16778 6.07321 1.875 5.64302 1.875H4.5C3.46447 1.875 2.625 2.71447 2.625 3.75V5.625Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                1-800-774-6237
                            </a>
                        </div>
                        <div class="header-actions-container-section">    
                            <a class="header-action has-line" href='${$bookURL}/captaincircle/specialOfferRegistration.page?track=header-mobile&trackId=special-offers' data-track="header" data-track-id="Subscribe">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.875 5.625V14.375C18.875 15.4105 18.0355 16.25 17 16.25H4.5C3.46447 16.25 2.625 15.4105 2.625 14.375V5.625M18.875 5.625C18.875 4.58947 18.0355 3.75 17 3.75H4.5C3.46447 3.75 2.625 4.58947 2.625 5.625M18.875 5.625V5.82726C18.875 6.47837 18.5372 7.08287 17.9827 7.42412L11.7327 11.2703C11.13 11.6411 10.37 11.6411 9.76732 11.2703L3.51732 7.42412C2.96279 7.08287 2.625 6.47837 2.625 5.82726V5.625" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                Subscribe
                            </a>
                            ${isGuestAuthenticated ? 
                            `<a class="header-action" href="${$wwwURL}/cruise-vacation-planning-dashboard/home.do" data-track="header" data-track-id="saved">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Icon">
                                <path id="Union" d="M18.75 7.09091C18.75 4.83156 16.8845 3 14.5833 3C12.8628 3 11.3858 4.02389 10.75 5.48493C10.1142 4.02389 8.63722 3 6.91667 3C4.61548 3 2.75 4.83156 2.75 7.09091C2.75 13.6551 10.75 18 10.75 18C10.75 18 18.75 13.6551 18.75 7.09091Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </g>
                                </svg>
                                Saved
                            </a>` : ''}
                        </div>
                    </div>
                    <div id="header-login-buttons-container" class="header-buttons-container">
                        <div id="manage-booking-button" class="manage-booking-button">
                            <button class="header-button" data-track-component-level-1="header" data-track="manage-dropdown" data-track-id="manage-booking">
                                <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8.75" cy="9" r="8" stroke="#6C6C78" stroke-width="1.5"></circle>
                                    <path d="M9.03447 10.3663L5.50054 12.3797L7.51271 8.84363L9.03447 10.3663Z" stroke="#6C6C78"></path>
                                    <path d="M8.59854 7.76595L12.1306 5.75265L10.1207 9.28809L8.59854 7.76595Z" stroke="#6C6C78"></path>
                                    </svg>
                                Manage Booking
                                <span class="header-chevron">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 12.9162C9.78655 12.9162 9.57322 12.8346 9.41072 12.6721L6.07738 9.33872C5.75155 9.01289 5.75155 8.48622 6.07738 8.16039C6.40322 7.83456 6.92988 7.83456 7.25572 8.16039L10.0099 10.9146L12.754 8.26456C13.0865 7.94539 13.6124 7.95456 13.9324 8.28539C14.2524 8.61622 14.2432 9.14456 13.9124 9.46372L10.579 12.6821C10.4165 12.8387 10.2082 12.9162 9.99988 12.9162Z" fill="#020215"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div id="login-button" class="login-button">
                            <button class="header-button" data-track-component-level-1="header" data-track="login-dropdown" data-track-id="login">
                                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.0669 16.4776C14.8497 14.8711 12.921 13.8333 10.75 13.8333C8.57898 13.8333 6.65035 14.8711 5.43308 16.4776M16.0669 16.4776C17.7131 15.0123 18.75 12.8772 18.75 10.5C18.75 6.08172 15.1683 2.5 10.75 2.5C6.33172 2.5 2.75 6.08172 2.75 10.5C2.75 12.8772 3.78689 15.0123 5.43308 16.4776M16.0669 16.4776C14.6536 17.7357 12.791 18.5 10.75 18.5C8.70896 18.5 6.84645 17.7357 5.43308 16.4776M13.4167 8.5C13.4167 9.97276 12.2228 11.1667 10.75 11.1667C9.27724 11.1667 8.08333 9.97276 8.08333 8.5C8.08333 7.02724 9.27724 5.83333 10.75 5.83333C12.2228 5.83333 13.4167 7.02724 13.4167 8.5Z" stroke="#6C6C78" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                ${isGuestAuthenticated ? `My Account` : `Login`}
                                <span class="header-chevron">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99988 12.9162C9.78655 12.9162 9.57322 12.8346 9.41072 12.6721L6.07738 9.33872C5.75155 9.01289 5.75155 8.48622 6.07738 8.16039C6.40322 7.83456 6.92988 7.83456 7.25572 8.16039L10.0099 10.9146L12.754 8.26456C13.0865 7.94539 13.6124 7.95456 13.9324 8.28539C14.2524 8.61622 14.2432 9.14456 13.9124 9.46372L10.579 12.6821C10.4165 12.8387 10.2082 12.9162 9.99988 12.9162Z" fill="#020215"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        <div class="global-header__nav-container-overlay hidden-md-up"></div>`;

        manageBookingButton.appendChild(manageBookingMobileTemplate.content.cloneNode(true));
    }

    buildManageBookingModalTemplate() {
        const manageBookingButton = this.shadowRoot.querySelectorAll('.manage-booking-button');
        const manageBookingModalTemplate = document.createElement('template');

        manageBookingModalTemplate.innerHTML = `
        <div id="manage-booking-modal" style="display: none;">
            <div class="manage-booking-modal-container">
                <div class="manage-booking-modal-item">
                    <a class="manage-booking-modal-item-link header-action" href="${$bookURL}/manage-booking/index.page?cpfy=checkin" data-track="header" data-track-id="checkin">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99935 1.66699C6.77435 1.66699 4.16602 4.27533 4.16602 7.50033C4.16602 10.9753 7.84935 15.767 9.36602 17.592C9.69935 17.992 10.3077 17.992 10.641 17.592C12.1493 15.767 15.8327 10.9753 15.8327 7.50033C15.8327 4.27533 13.2243 1.66699 9.99935 1.66699Z" stroke="#003595"/>
                        <circle cx="10" cy="7.5" r="2.5" stroke="#003595"/>
                        </svg>
                        Check In
                    </a>
                </div>
                <div class="manage-booking-modal-item">
                    <a class="manage-booking-modal-item-link header-action" href="${$bookURL}/manage-booking/index.page?cpfy=shoreex" data-track="header" data-track-id="shore-excursions">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.1665 6.29167V2.75C8.1665 2.61193 8.27843 2.5 8.4165 2.5H10.0832C10.2212 2.5 10.3332 2.61193 10.3332 2.75V5.20833" stroke="#003595"/>
                        <path d="M16.8335 6.29167V2.75C16.8335 2.61193 16.7216 2.5 16.5835 2.5H14.9168C14.7788 2.5 14.6668 2.61193 14.6668 2.75V5.20833" stroke="#003595"/>
                        <path d="M6 9.75726V21.7498C6 21.8878 6.11193 21.9998 6.25 21.9998H18.75C18.8881 21.9998 19 21.8878 19 21.7498V9.75726C19 8.85036 18.6684 7.97476 18.0676 7.2954C15.1095 3.95046 9.89054 3.95046 6.93242 7.2954C6.33163 7.97476 6 8.85036 6 9.75726Z" stroke="#003595"/>
                        <path d="M8.16602 14.7917H16.041C16.1791 14.7917 16.291 14.9037 16.291 15.0417V17.5001" stroke="#003595"/>
                        </svg>
                        Shore Excursions
                    </a>
                </div>
                <div class="manage-booking-modal-item">
                    <a class="manage-booking-modal-item-link header-action" href="${$bookURL}/manage-booking/index.page?cpfy=flightsummary" data-track="header" data-track-id="flights-and-transfers">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1064_19907)">
                        <path d="M8.98365 14.8444L8.56504 10.8613C8.53164 10.5435 8.21281 10.3382 7.90966 10.4392L1.65811 12.5231C1.33435 12.631 1 12.39 1 12.0487V11.8604C1 11.6922 1.08461 11.5352 1.22516 11.4428L8.64984 6.55725C8.79039 6.46477 8.875 6.3078 8.875 6.13956V2.09819C8.875 2.08326 8.87618 2.06835 8.87852 2.05361C9.08145 0.777267 10.9185 0.777267 11.1215 2.05361C11.1238 2.06835 11.125 2.08326 11.125 2.09819V6.13956C11.125 6.3078 11.2096 6.46477 11.3502 6.55725L18.7748 11.4428C18.9154 11.5352 19 11.6922 19 11.8604V12.0487C19 12.39 18.6657 12.631 18.3419 12.5231L12.0903 10.4392C11.7872 10.3382 11.4684 10.5435 11.435 10.8613L11.0164 14.8444C11.0057 14.9457 11.0263 15.0479 11.0753 15.1372L12.8348 18.3442C13.1093 18.8445 12.4662 19.3402 12.0523 18.9474L10.3442 17.3266C10.1512 17.1435 9.84876 17.1435 9.65583 17.3266L7.94772 18.9474C7.53378 19.3402 6.89071 18.8445 7.1652 18.3442L8.92474 15.1372C8.97374 15.0479 8.99429 14.9457 8.98365 14.8444Z" stroke="#003595"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1064_19907">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        Flights and Transfers
                    </a>
                </div>
                <div class="manage-booking-modal-item">
                    <a class="manage-booking-modal-item-link header-action" href="${$bookURL}/manage-booking/index.page?cpfy=insurance" data-track="header" data-track-id="vacation-protection-plan">
                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70922 2.04066L2.68483 4.05327C2.27744 4.17008 2 4.51595 2 4.90763V10.6475C2 14.9868 5.18369 18.8197 9.74161 19.9682C9.82622 19.9895 9.91311 20 10 20C10.0869 20 10.1738 19.9895 10.2584 19.9682C14.8163 18.8197 18 14.9868 18 10.6479V4.90798C18 4.5163 17.7226 4.17008 17.3152 4.05327L10.2908 2.04066C10.1014 1.98645 9.89863 1.98645 9.70922 2.04066Z" stroke="#003595"/>
                        <path d="M14 7L9.42426 13.5L7 10.9238" stroke="#003595" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Vacation Protection Plan
                    </a>
                </div>
            </div>
        </div>`;

        manageBookingButton.forEach(button => {
            if(!button.querySelector('#manage-booking-button')) {
                button.appendChild(manageBookingModalTemplate.content.cloneNode(true));
            }
        })
    }

    buildLoginModalTemplate() {
        let isGuestAuthenticated = this.getAttribute('guest-auth') === 'true';
        const loginButtons = this.shadowRoot.querySelectorAll('.login-button');
        const loginModalTemplate = document.createElement('template');
        if(isGuestAuthenticated){
            loginModalTemplate.innerHTML = `
            <div id="login-modal" style="display: none;">
                <div class="login-modal-container">
                        <a class="login-modal-item-link header-action" href="${$bookURL}/captaincircle/myPrincess.page" data-track="header" data-track-id="account">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM4 20V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V20H4ZM6 18H18V17.2C18 17.0167 17.9542 16.85 17.8625 16.7C17.7708 16.55 17.65 16.4333 17.5 16.35C16.6 15.9 15.6917 15.5625 14.775 15.3375C13.8583 15.1125 12.9333 15 12 15C11.0667 15 10.1417 15.1125 9.225 15.3375C8.30833 15.5625 7.4 15.9 6.5 16.35C6.35 16.4333 6.22917 16.55 6.1375 16.7C6.04583 16.85 6 17.0167 6 17.2V18ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10Z" fill="#003595"/>
                            </svg>
                            Account
                        </a>
                        <a class="login-modal-item-link header-action" href="${$bookURL}/captaincircle/membershipBenefits.page" data-track="header" data-track-id="captains-circle">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4470_6906)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76785 2.29832C8.84491 2.00519 9.96916 1.92708 11.0764 2.06843C12.1837 2.20979 13.2522 2.56785 14.2211 3.12217C15.0852 3.61653 15.8548 4.25834 16.4957 5.01766L17.8123 4.25753C17.0259 3.27337 16.0603 2.44627 14.966 1.8202C13.8261 1.16806 12.569 0.746809 11.2664 0.580508C9.96371 0.414207 8.64107 0.50611 7.37394 0.850969C6.10681 1.19583 4.92001 1.78689 3.88131 2.59041C2.84261 3.39393 1.97234 4.39416 1.3202 5.53401C0.668056 6.67386 0.246808 7.931 0.0805077 9.23364C-0.0857929 10.5363 0.00611028 11.8589 0.35097 13.1261C0.69583 14.3932 1.28689 15.58 2.09041 16.6187C2.89393 17.6574 3.89417 18.5277 5.03401 19.1798C6.17386 19.8319 7.431 20.2532 8.73364 20.4195C10.0363 20.5858 11.3589 20.4939 12.6261 20.149C13.8932 19.8042 15.08 19.2131 16.1187 18.4096C16.7489 17.9221 17.3172 17.3621 17.8123 16.7425L16.4957 15.9823C16.11 16.4394 15.6762 16.8555 15.2009 17.2232C14.318 17.9061 13.3092 18.4085 12.2322 18.7017C11.1551 18.9948 10.0308 19.0729 8.9236 18.9316C7.81635 18.7902 6.74778 18.4322 5.77891 17.8778C4.81004 17.3235 3.95984 16.5838 3.27685 15.7009C2.59386 14.818 2.09146 13.8092 1.79832 12.7322C1.50519 11.6551 1.42708 10.5308 1.56843 9.4236C1.70979 8.31635 2.06785 7.24778 2.62217 6.27891C3.17649 5.31004 3.91622 4.45984 4.79911 3.77685C5.68201 3.09386 6.69079 2.59145 7.76785 2.29832Z" fill="#003595"/>
                            <path d="M18.5856 8.09171C16.8278 7.85604 15.8658 6.36478 13.9882 7.59333C12.8446 8.34283 12.4158 8.97256 10.1557 7.58174C9.02373 6.88633 8.3515 6.82065 7.71018 6.77816C5.67804 6.6468 3.95111 8.22306 3.85453 10.2861C3.80044 11.3949 4.1945 12.3762 4.944 13.0755C5.03672 13.1527 5.13717 13.1334 5.18353 13.0407L5.20285 12.9905L6.36186 10.1973C6.36186 10.1973 6.99159 8.55531 8.54081 9.06914C9.03919 9.23141 9.45257 9.39367 9.82345 9.50571C10.7082 9.77614 11.3572 9.78387 12.381 8.9687C12.8485 8.59781 13.6173 8.08012 14.614 8.53986C16.0087 9.18118 18.3384 10.769 19.9996 7.75946C19.9996 7.75946 19.3351 8.18443 18.5817 8.08398M10.0244 9.74524C11.9638 10.939 12.2728 10.8888 13.8839 9.82637C15.9624 8.46259 17.0982 10.2745 18.5277 10.4677C19.281 10.5681 19.9417 10.1432 19.9417 10.1432C18.4002 12.9403 16.2483 11.5997 14.5097 10.7806C12.8678 10.0118 11.7667 12.2526 10.0321 11.9628C9.97799 11.9512 9.94708 12.0015 9.98572 12.0246C12.041 13.288 12.891 12.6582 13.7757 12.0749C15.9121 10.6763 17.1253 12.5616 18.5045 12.7471C19.2578 12.8514 19.9185 12.4226 19.9185 12.4226C18.1568 15.6176 15.9083 13.6434 14.9695 13.2107C14.2277 12.8707 13.8298 12.4419 12.1685 13.2455C10.7159 13.9486 8.79579 14.5358 7.03409 14.0065L7.01864 13.9988C7.2234 13.4348 7.53633 12.6969 8.08107 12.7046C8.23947 12.7046 9.10873 12.7625 9.20145 12.7471C9.30576 12.7316 9.3753 12.6582 9.39462 12.5771C9.43712 12.3878 9.35985 12.3453 9.35212 12.2835C9.34439 12.2217 9.40621 12.2023 9.45257 12.1637C9.5337 12.098 9.54143 12.0478 9.52597 11.9937C9.51052 11.9474 9.39848 11.9319 9.36371 11.9087C9.32508 11.8894 9.33667 11.8237 9.39462 11.816C9.6921 11.7774 9.67665 11.619 9.64188 11.5649C9.51825 11.3447 9.71142 11.306 9.78096 11.2906C9.87368 11.2674 10.0475 11.2017 9.99731 11.0356C9.93936 10.8347 9.8814 10.6879 9.83891 10.5179C9.77323 10.2436 9.70369 10.0002 9.76937 9.84182C9.85436 9.6332 9.94322 9.69115 10.0244 9.74137" fill="#003595"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_4470_6906">
                            <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                            </defs>
                            </svg>
                            Captain's Circle
                        </a>
                        <a class="login-modal-item-link header-action" href="${$bookURL}/manage-booking/index.page" data-track="header" data-track-id="manage-booking">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <circle cx="9" cy="9" r="8" stroke="#003595" stroke-width="1.5"/>
                                <path d="M9.28447 10.3663L5.75054 12.3797L7.76271 8.84363L9.28447 10.3663Z" stroke="#003595"/>
                                <path d="M8.84854 7.76595L12.3806 5.75265L10.3707 9.28809L8.84854 7.76595Z" stroke="#003595"/>
                            </svg>
                            Manage Booking
                        </a>
                        <a class="login-modal-item-link header-action cc-logout-button" href="#" data-track="header" data-track-id="logout">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15.3169 16.4776C14.0997 14.8711 12.171 13.8333 10 13.8333C7.82898 13.8333 5.90035 14.8711 4.68308 16.4776M15.3169 16.4776C16.9631 15.0123 18 12.8772 18 10.5C18 6.08172 14.4183 2.5 10 2.5C5.58172 2.5 2 6.08172 2 10.5C2 12.8772 3.03689 15.0123 4.68308 16.4776M15.3169 16.4776C13.9036 17.7357 12.041 18.5 10 18.5C7.95896 18.5 6.09645 17.7357 4.68308 16.4776M12.6667 8.5C12.6667 9.97276 11.4728 11.1667 10 11.1667C8.52724 11.1667 7.33333 9.97276 7.33333 8.5C7.33333 7.02724 8.52724 5.83333 10 5.83333C11.4728 5.83333 12.6667 7.02724 12.6667 8.5Z" stroke="#003595" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Log Out
                        </a>
                </div>
            </div>`;

        } else {
            loginModalTemplate.innerHTML = `
            <div id="login-modal" style="display: none;">
                <div class="login-modal-container">
                    <p class="login-modal-login-text">
                        Already booked? Log in or <a class="cc-register-link" alt="Log in" href="${$wwwURL}/my-princess/register/" data-track="header" data-track-id="register">create an account</a>.
                    </p>
                    <a class="cc-blue-outline-btn cc-login" type="button" href="${$wwwURL}/my-princess/login/${this.getLoginSrc()}" data-track="header" data-track-id="login"><span class="btn-txt">LOG IN</span></a>
                </div>
            </div>`;
        }

        loginButtons.forEach(button => {
            if (!button.querySelector('#login-modal')) {
                button.appendChild(loginModalTemplate.content.cloneNode(true));
            }
        });
    }

    getLoginSrc() {
        const currentPath = window.location.pathname;
        let src = '?src=';

        if (currentPath.startsWith('/cruise-search/')) {
            src += 'cruise-search';
        } else if (currentPath.startsWith('/brochures/')) {
            src +=  'brochures';
        } else if (currentPath.startsWith('/cruisepersonalizer/') || currentPath.startsWith('/manage-booking/')) {
            src +=  'cruisepersonalizer';
        } else if (currentPath.startsWith('/my-princess/')) {
            src = '';
        } else {
            src += encodeURIComponent(window.location.href);
        }
        return src;
    }

    linkStylesheet(url) {
        return new Promise((resolve, reject) => {
            let link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.onload = () => resolve();
            link.onerror = () => reject();
            link.href = url;

            let headScript = document.querySelector('script');
            headScript.parentNode.insertBefore(link, headScript);
        });
    }

    setupEventListeners() {        
        this.headerLoginButtonsContainer = this.shadowRoot.querySelector('#header-login-buttons-container');
        this.manageBookingButton = this.shadowRoot.querySelectorAll('.manage-booking-button button');
        this.manageBookingModal = [...this.shadowRoot.querySelectorAll('#manage-booking-modal')];
        this.loginButton = this.shadowRoot.querySelectorAll('.login-button button');
        this.loginLink = this.shadowRoot.querySelector('.cc-login');
        this.loginModals = [...this.shadowRoot.querySelectorAll('#login-modal')];
        this.logoutButton = this.shadowRoot.querySelectorAll('.cc-logout-button');
        this.subscribeButton = this.shadowRoot.querySelector('.subscribeModal');

        if(this.loginLink && this.getAttribute('login-in-modal') === 'true') {
            this.loginLink.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.closeAllModals();
                window.dispatchEvent(new CustomEvent('web-component.onClick', {
                    detail: {
                        src: this.loginLink.href,
                        type: 'iframe',
                        mainClass: "mfp-entry-point mfp-entry-point-login"
                    }
                }));
            });
        }

        this.loginButton.forEach((button, index) => {
            button.addEventListener('click', (e) => {
                e.stopPropagation();
                this.toggleModal(this.loginModals[index], button);
            });
        });

        this.manageBookingButton.forEach((button, index) => {
            button.addEventListener('click', (e) => {
                e.stopPropagation();
                this.toggleModal(this.manageBookingModal[index], button);  // Use single toggleModal function
            });
        });
                
        this.logoutButton.forEach(button => button.addEventListener('click', (event) => {
            event.preventDefault();
            window?.guestAuth.logout(false);
        }, {once: true}));
        if(this.subscribeButton) {
            this.subscribeButton.addEventListener('click', (e) => this.toggleSubscribeModal(e));
        }

        document.addEventListener('click', this.closeAllModals.bind(this));
    }

    closeAllModals() {
        this.manageBookingModal.forEach(modal => {
            modal.style.display = 'none';
        });
        this.manageBookingButton.forEach(button => button.setAttribute('aria-expanded', 'false'));
    
        this.loginModals.forEach(modal => {
            modal.style.display = 'none';
        });
        this.loginButton.forEach(button => button.setAttribute('aria-expanded', 'false'));
    }
    
    toggleModal(modal, button) {        
        if (modal.style.display === 'none') {
            this.closeAllModals();
            modal.style.display = 'block';
            button.setAttribute('aria-expanded', 'true');
        } else {
            modal.style.display = 'none';
            button.setAttribute('aria-expanded', 'false');
        }
    }

    toggleSubscribeModal(e) {
        e.preventDefault();
        window.dispatchEvent(new CustomEvent('web-component.onClick', {
            detail: {
                src: this.subscribeButton.href,
                type: 'iframe',
                mainClass: "special-offers"
            }
        }));
    }
}

if (!window.customElements.get('pcl-captain-circle-header')) {
    customElements.define("pcl-captain-circle-header", PclCaptainCircleHeader);
}
