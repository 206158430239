/**
 * [droptabs] - Load required files for droptabs and easytabs
 *
 * @param {jQuery Object} $droptab element (unordered list) that will become droptabs
 * @param {string} string selector for the container of the droptab and corresponding divs for easytabs
 */

var droptabs = {
	init: function ($droptab,container) {
		if (typeof $.fn.droptab == 'function') {
			droptabs.activate($droptab,container);
		} else {
			droptabs.loadDroptab($droptab,container);
		}//end drop-tab check
	},
	loadDroptab: function ($droptab,container) {
		/* load files async - using callbacks and setInterval to create my own "when().then() call */
		var droptabCSSLoaded = false,
		    droptabJSLoaded = false;

		//loading css and js, set vars to true once done
		var loadDroptabCSS = loadCSS('/css/global/droptab/droptab.css');
		onloadCSS( loadDroptabCSS, function() {
			droptabCSSLoaded = true;
		});
		loadJS('/js/global/jquery/plugins/droptab/jquery.droptab.js', function () {
			droptabJSLoaded = true;
		});

		//check every 50ms to see if the file have loaded, then proceed with initializing the droptabs
		var loadCounter = 0,
		    loadCheck = setInterval(function() {
			if (droptabCSSLoaded == true && droptabJSLoaded == true) {
				clearInterval(loadCheck);
				droptabs.activate($droptab,container);
			}
		},50);
	},
	activate: function ($droptab,container) {
		var breakWidthn, breakContainer;
		//set breakWidth to auto if not defined
		if ($droptab.data('breakWidth') == 'undefined') {
			breakWidth = 'auto';
		} else {
			breakWidth = $droptab.data('breakWidth');
		}
		//set breakTarget to auto if not defined
		if ($droptab.data('breakTarget') == 'undefined') {
			breakTarget = 'auto';
		} else {
			breakTarget = $droptab.data('breakTarget');
		}

		$droptab.droptab({
			breakWidth: breakWidth,
			breakTarget: breakTarget,
			openMsg: 'Select an option',
			autoClose: true,
			droptabCallback: function () {
				var $droptab = $(this);
				//display droptab once initiated
				$droptab.removeClass('invisible').closest('.drop-tab-wrapper').removeClass('not-ready');
				if ($droptab.data('easytabs') == true) {
					droptabs.loadEasytab($droptab,container);
				} else if ($droptab.data('setactive') == true) {
					droptabs.selectActive($droptab);
				}
			}
		});
	},
	selectActive: function ($droptab) {
		var path = window.location.pathname;
		path = path.split('#')[0];
		path = path.split('index')[0];
		if(path.slice(-1) == '/'){
			path = path.slice(0,-1);
		}
		var target = path.split('/').pop(),
		    compare = '';
		$droptab.find('a').each(function () {
			var $link = $(this),
			    href = $link.prop('href');
			if(href.slice(-1) == '/'){
				href = href.slice(0,-1);
			}
			hrefArray = href.split('/');
			for(var i = hrefArray.length ; i > 0 ; i--){
				compare = hrefArray[i];
				if(target == compare){
					$link.parent().addClass('active');
					$('.droptab-select-current').text($link.text());
					return false;
				}
			} 
		});
	},
	loadEasytab: function ($droptab,container) {
		if (typeof $.easytabs == 'function') {
			droptabs.activateEasytabs($droptab,container);
		} else {
			/*load files async*/
			loadJS('/js/global/jquery/plugins/easytabs/jquery.easytabs.min.js', function () {
				droptabs.activateEasytabs($droptab,container);
			});
		}
	},
	activateEasytabs: function ($droptab,container) {
		var $container = $(container);
		
		//initiate easytabs
		$droptab.closest(container).easytabs({
			tabs: '.drop-tab li',
			updateHash: false,
			transitionIn: 'fadeIn',
			transitionOut: 'fadeOut'
		});

		//quick jump link to click on an easy tab. link in jump link must match the url (not including the hash tag) in the .drop-tab > .tab
		if ($container.find('.easy-tab-jump')) {
			$container.on('click', '.easy-tab-jump', function (e) {
				e.preventDefault();

				var $jumpLink = $(this);
					jumpLinkTarget = $jumpLink.attr('href');

				$('.drop-tab > .tab').each(function () {
					var $tabLink = $(this).find('a'),
						target = $tabLink.attr('href').split(' ');

					for (var i=0; i<target.length; i++) {
						if (target[i] == jumpLinkTarget) {
							$tabLink.trigger('click');
						}
					}
				});
				$('html,body').stop().animate({scrollTop: ($container.offset().top)-20 }, 500);
			});
		}
	}
};