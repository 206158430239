jQuery.extend({
	getUrlVars: function(){
		var vars = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++)
		{
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	},
	getUrlVar: function(name){
		return $.getUrlVars()[name];
	}, 
	removeUrlVar:function(name){
		var urlparts= window.location.href.split('?');
		if (urlparts.length>=2)
		{
			var urlBase=urlparts.shift(); //get first part, and remove from array
			var queryString=urlparts.join("?"); //join it back up

			var prefix = encodeURIComponent(name)+'=';
			var pars = queryString.split(/[&;]/g);
			for (var i= pars.length; i-->0;)               //reverse iteration as may be destructive
			  if (pars[i].lastIndexOf(prefix, 0)!==-1)   //idiom for string.startsWith
				  pars.splice(i, 1);
			url = urlBase+'?'+pars.join('&');
		}
		return url;
	}
});//end url parameters