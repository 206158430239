/*
	Guest Profile - finds information about user (like if they have future bookings, loyalty status, past passenger) to be then used for more personalized experiences on Princess.com	
 */

var guestProfile = {
	/**
	 * [timestamp] - new date object based on current time
	 * @type {Date}
	 */
	timestamp: new Date(),

	/**
	 * [cookieName] - updates the cookie name based on the hosting environment
	 * @param  {string} cookieName - name of cookie
	 * @return {string}            - returns modified name of cookie with environment appended to the cookie name. ie. pcl_guestInfoStage
	 */
	cookieName: function (cookieName) {
		if (sub_domain_substring != null) {
			switch (sub_domain_substring[0]) {
				case 'dev':
					cookieName += 'Dev'
					break;
				case 'stage':
					cookieName += 'Stage'
					break;
				case 'uat':
					cookieName += 'Uat'
					break;
				case 'qa':
					cookieName += 'Qa'
					break;
				case 'stg':
					cookieName += 'Stg'
					break;
				case 'tst':
					cookieName += 'Tst'
					break;
				case 'preprod':
					cookieName += 'Preprod'
					break;
				default:
					//don't update the cookie name
					break;
			}
		}
		return cookieName;
	},

	/**
	 * [expireDatestamp] - sets the expiration date for cookies. 
	 * @return {[date object]} - 1 year from timestamp
	 */
	expireDatestamp: function () {
		var expire = new Date(guestProfile.timestamp.getFullYear()+1, guestProfile.timestamp.getMonth(), guestProfile.timestamp.getDate(), guestProfile.timestamp.getHours(), guestProfile.timestamp.getMinutes());
		return expire;
	},

	/**
	 * [isStale] - a timestamp older than 1 day, is stale 
	 * @param  {number}  timestamp - last updated unix timestamp from cookie, in seconds
	 * @return {Boolean}           - return true for being stale (to refresh guestInfo data) when
	 * 								 1. no sessionTimestamp
	 * 								 2. timestamp and sessionTimestamp do not match
	 * 								 3. if timestamp is older than 1 day
	 */
	isStale: function (timestamp, sessionTimestamp) {
		// staleTimestamp formula: (Date.now - 1 Day) / 1000 == a timestamp 1 day ago in seconds
		var staleTimestamp = (Date.now() - 1000 * 60 * 60 * 24) / 1000;
		return !sessionTimestamp || timestamp !== sessionTimestamp || timestamp < staleTimestamp;
	},

	/**
	 * isReady - pass guestInfo or futureBooking data to a callback function
	 * @param  {string}   data - should the name of cookie that has the data needed
	 * @param  {Function} callback - name of function to be called when the is available
	 */
	isReady: function (data, callback) {
		var dataObj, customEvent;
		if (data === 'pcl_futureBooking') {
			dataObj = guestProfile.getFutureBooking();
			customEvent = 'guestProfile.futureBookingSaved';
		} else if (data === 'pcl_guestInfo') {
			dataObj = guestProfile.getInfo();
			customEvent = 'guestProfile.infoSaved';
		} else {
			return false;
		}
		if (!!dataObj) {
			callback(dataObj);
		} else {
			$(document).on(customEvent, function () {
				dataObj = (data === 'pcl_futureBooking') ? guestProfile.getFutureBooking() : guestProfile.getInfo();
				callback(dataObj);
			});
		}
	},

	/**
	 * [getData] - simple ajax to be used in defer methods for info, futureBooking, and addFutureBooking
	 * @param  {[string]} targetUrl - url to post ajax call to
	 * @param  {string} data        - string of parameters to submit
	 * @return {[type]}             - results of ajax call
	 */
	getData: function (targetUrl,data) {
		//set the target url based on environment url
		targetUrl = $bookURL + targetUrl;

		return $.ajax({
			url: targetUrl,
			cache: false,
			contentType: 'application/json',
			data: data,
			dataType: 'jsonp'
		});
	},

	/**
	 * [info] - makes a call to create the pcl_guestInfo cookie and set P13 weights if
	 * 			1. ccn is supplied
	 * 				- let's get the guestInfo regardless of the hasGuestInfoCookie
	 *				- supplying the ccn will force a refresh of the guestInfo cookie
	 *				- this is primarily used by CP booking number sign in
	 * 			2. pcl_guestInfo is stale
	 * 			3. if pcl_guestInfo does not exist, yet sccn or pccn does exist
	 * 			
	 * 		  - if pcl_guestInfo exists, or upon a success response from call, this run a check on futureBookings
	 *		  - will trigger a custom event guestProfile.infoSaved to be used as a call back in other programs
	 */
	info: function (ccn) {
		var guestInfoCookieName = guestProfile.cookieName('pcl_guestInfo'),
            hasGuestInfoCookie = docCookies.hasItem(guestInfoCookieName),
            guestInfoObj = hasGuestInfoCookie ? JSON.parse(docCookies.getItem(guestInfoCookieName)) : {},
            guestInfoStatusCookieName = guestProfile.cookieName('pcl_guestInfoStatus'),
            guestInfoStatus = docCookies.hasItem(guestInfoStatusCookieName) ? JSON.parse(docCookies.getItem(guestInfoStatusCookieName)) : {},
            isGuestInfoStale;

		// ccn is supplied, will force hasGuestInfoCookie to false because we want to ignore it when CCN is supplied
		if (ccn != undefined) {
			ccn = 'CCN=' + ccn;
			hasGuestInfoCookie = false;
		}

		// check freshness of guestInfo
		if (hasGuestInfoCookie) {
			isGuestInfoStale = guestProfile.isStale(guestInfoObj.lastUpdated, guestInfoStatus);
			if (ccn == undefined) {
				ccn = 'CCN=' + guestInfoObj.ccn;
			}
		}

		// if ccn is unknown, try to get the ccn from the pccn if it is available
		if (ccn == undefined && docCookies.hasItem('pccn')) {
			ccn = 'CCN=' + docCookies.getItem('pccn');
		}

		// if ccn is unknown, try to get the ccn from the sccn if it is available - probably not needed, because guestInfo cookie will be refreshed on login.
		if (ccn == undefined && docCookies.hasItem('sccn')) {
			ccn = 'CCN=' + docCookies.getItem('sccn');
		}

		// if ccn is unknown, and user logins via CP with booking number, try to get the ccn from the pcl_cpguest if it is available
		if ( docCookies.hasItem('pcl_cpguest') ) {
			var cpguestCookie = docCookies.getItem('pcl_cpguest');
			if ( (ccn == undefined) || (cpguestCookie != guestInfoObj.ccn) ) {
				ccn = 'CCN=' + cpguestCookie;
				hasGuestInfoCookie = false;
			}
		}

		// get guestInfo if not available or guestInfoStatus is N, when we know the guest's ccn
		if ((!hasGuestInfoCookie || isGuestInfoStale) && ccn != undefined) {
			$.when(
				guestProfile.getData('/captaincircle/retrieveGuestInfo.do',ccn)
			).then(function (results) {
				if (results.status === 'success') {
					$(document).trigger('guestProfile.infoSaved');

					//update guestInfoObj
					guestInfoObj = JSON.parse(docCookies.getItem(guestInfoCookieName));

					// run futureBooking once guestInfo is saved to cookie
					guestProfile.futureBooking(true);
				}
			}, function (req,error) {
				if (error === 'error') {
					error = req.statusText;
				}
				return false;
			});
		} else {
			// run futureBooking if guestInfo cookie is present
			if (hasGuestInfoCookie) {
				guestProfile.futureBooking(false);
			}

			if (guestInfoStatus === null) { 
				docCookies.setItem(guestInfoStatusCookieName, Date.now(), '', '/', 'princess.com', true);
			}
		}
	},

	/**
	 * [getInfo] - retrieves and parses the cookie pcl_guestInfo
	 * @return {[object]} - JSON Object of the contents in pcl_guestInfo
	 */
	getInfo: function () {
		//Retrieve the object form storage
		var guestInfoCookieName = guestProfile.cookieName('pcl_guestInfo'),
			info = docCookies.getItem(guestInfoCookieName);

		if (info == null || info == 'undefined') {
			return null;
		} else {
			return JSON.parse(info);
		}
	},

	/**
	 * [futureBooking] - makes a call to get the JSON for future bookings, then saves the results in pcl_fuctureBooking.
	 */
	futureBooking: function (loadFutureBooking) {
		var guestInfoCookieName = guestProfile.cookieName('pcl_guestInfo'),
			futureBookingCookieName = guestProfile.cookieName('pcl_futureBooking');
		//	guestInfoObj = guestProfile.getInfo(guestInfoCookieName),
		//	futureBookingObj = {},
		//	hasFutureBookingCookie = docCookies.hasItem(futureBookingCookieName);

		/*
		if (guestInfoObj.futureBooked == 'Y') {
			// make sure loadFutureBooking has been defined
			if (typeof loadFutureBooking == 'undefined') {
				var loadFutureBooking = false;
			}

			// if loadFutureBooking is set to false, we need to check a few scenarios to see if we need to load futureBookings.do
			if (loadFutureBooking == false) {
				//does pcl_futureBooking not exist, if yes call futureBooking.do
				if (!hasFutureBookingCookie) {
					loadFutureBooking = true;
				}

				//does pcl_futureBooking exits but the CCN does not match pcl_guestInfo, if yes call futureBookings.do
				if (hasFutureBookingCookie) {
					futureBookingObj = guestProfile.getFutureBooking();
					if (futureBookingObj == null || futureBookingObj.ccn == undefined || guestInfoObj.ccn != futureBookingObj.ccn) {
						loadFutureBooking = true;
					}
				}
			}

			// load futureBoookings.do and save results to pcl_futureBookings
			if (loadFutureBooking) {
				$.when(
					guestProfile.getData('/captaincircle/getFutureBookings.do','')
				).then(function (results) {
					var futureBooking = results.data;
					
					//save pcl_futureBooking cookie
					if (!!futureBooking) {
						guestProfile.saveFutureBooking(futureBooking);
					}
				}, function (req,error) {
					if (error === 'error') {
						error = req.statusText;
					}
					return false;
				});
			}
		}
		*/
		
		// when guest does not have futureBooking - force deleting to reduce cookie foot print
		//if (guestInfoObj.futureBooked == 'N') {
			if (docCookies.hasItem(futureBookingCookieName)) {
				docCookies.removeItem(futureBookingCookieName,'/','princess.com'); // need to switch null to 'princess.com'
			}
		//}
	},

	/**
	 * [getFutureBooking] - retrieves and parses the cookie pcl_futureBooking
	 * @return {[object]} - JSON Object of the contents in pcl_futureBooking
	 */
	getFutureBooking: function () {
		//Retrieve the object form storage
		var futureBookingCookieName = guestProfile.cookieName('pcl_futureBooking'),
			info = docCookies.getItem(futureBookingCookieName);

		if (info == null || info == 'undefined') {
			return null;
		} else {
			return JSON.parse(info);
		}
	},

	/**
	 * [addFutureBooking] - when a booking is made, updates to Siebel is almost real-time. Meaning, it could be 1-minute or 1-day... This function provides a way to manually add a booking to pcl_futureBooking without a hit to Siebel
	 * @param {[string]} bookingNum - booking ID, should be a hash
	 * @param {[string]} sailDate - unix timestamp
	 * @param {[string]} shipName - name of ship
	 * @param {[string]} cruiseDuration - number of cruise days
	 * @param {[string]} cruiseDest - destination name
	 * @param {[string]} companyCode - company sold cruise
	 * @param {[string]} productCode - 3 character product code
	 * @param {[string]} bookingStat - status of booking
	 * @param {[string]} loyaltyCredit - loyalty credit
	 */
	addFutureBooking: function (bookingNum,sailDate,shipName,cruiseDuration,cruiseDest,companyCode,productCode,bookingStat,loyaltyCredit) {
		
		var futureBookingObj = guestProfile.getFutureBooking(),
			bookingData;
			
		bookingData += 'bookingNum=' + bookingNum;
		bookingData += '&sailDate=' + sailDate;
		bookingData += '&shipName=' + shipName;
		bookingData += '&cruiseDuration=' + cruiseDuration;
		bookingData += '&cruiseDest=' + cruiseDest;
		bookingData += '&companyCode=' + companyCode;
		bookingData += '&productCode=' + productCode;
		bookingData += '&bookingStat=' + bookingStat;
		bookingData += '&loyaltyCredit=' + loyaltyCredit;

		$.when(
			guestProfile.getData('/captaincircle/getFutureBookingJSON.do',bookingData)
		).then(function (results) {
			var addBooking = results.data;

			if (futureBookingObj == null) {
				futureBookingObj = {"futureBookings":[]};
			}

			//add booking to futureBookingObj
			futureBookingObj.futureBookings.push(addBooking);
			
			// save pcl_futureBooking
			guestProfile.saveFutureBooking(futureBookingObj);
		}, function (req,error) {
			if (error === 'error') {
				error = req.statusText;
			}
			return false;
		});
	},

	/**
	 * [saveGuestInfo] - saves guest JSON object into pcl_guestInfo. Will trigger a custom event guestProfile.guestInfoSaved to be used as a call back in other programs
	 * @param  {[object]} guestInfo - futureBooking JSON object
	 */
	saveInfo: function (guestInfo) {
		var guestInfoCookieName = guestProfile.cookieName('pcl_guestInfo');
		docCookies.setItem(guestInfoCookieName,JSON.stringify(guestInfo),guestProfile.expireDatestamp(),'/','princess.com',true);
		$(document).trigger('guestProfile.guestInfoSaved');
	},

	/**
	 * [saveFutureBooking] - saves futureBooking JSON object into pcl_futureBooking. Will trigger a custom event guestProfile.futureBookingSaved to be used as a call back in other programs
	 * @param  {[object]} futureBooking - futureBooking JSON object
	 */
	saveFutureBooking: function (futureBooking) {
		var futureBookingCookieName = guestProfile.cookieName('pcl_futureBooking');
		docCookies.setItem(futureBookingCookieName,JSON.stringify(futureBooking),guestProfile.expireDatestamp(),'/','princess.com',true);
		$(document).trigger('guestProfile.futureBookingSaved');
	}
};

$(document).ready(function () {
	// Commenting out as per Ryan's feedback, will be uitilizing new data from CRM API
	//guestProfile.info();
});